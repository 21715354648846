// library
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Pagination, Tag, Modal, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";

// store
import {
  fetchProductData,
  deleteProductData,
} from "../../redux/store/productSlice";

// part component
import Input from "../part/Input";
import ModalComponent from "../part/Modal";
import CustomButtonGroup from "../part/GroupButton";
import TableData from "../part/Table";

// component
import TambahProduk from "./TambahProduk";
import MassUpload from "./MassUpload";
import imageNotFound from "../../images/notavailable.png";
import SingleButton from "../part/Button";

// replacer
const { confirm } = Modal;

const TableProduk = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading, totalRows } = useSelector((state) => state.product);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("tambah");
  const [searchTerm, setSearchTerm] = useState("");

  const handleShowTambah = () => {
    navigate("/produk/tambah");
  };

  const handleShowDetail = (productId) => {
    navigate(`/produk/detail/${productId}`);
  };

  const handleDelete = async (productId) => {
    confirm({
      title: "Yakin Ingin Menonaktifkan Produk Ini?",
      icon: <ExclamationCircleFilled />,
      content: "Product dapat aktif kembali setelah diaktifkan lewat edit",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          await dispatch(deleteProductData(productId));
          dispatch(fetchProductData(currentPage - 1));
        } catch (error) {
          console.error("Error deleting product:", error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleShowMassUpload = () => {
    setShowModal(true);
    setActionType("massUpload");
  };

  const handleClose = () => setShowModal(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchProductData({ page: page - 1, search: searchTerm }));
  };

  const columns = [
    {
      dataIndex: "id",
      title: "No",
      render: (text, record, index) => index + 1 + (currentPage - 1) * 10,
    },
    {
      dataIndex: "image",
      title: "Gambar",
      render: (text, record) =>
        record.image ? (
          <img
            src={record.image}
            alt={record.dataIndex}
            style={{ width: "50px", height: "50px" }}
          />
        ) : (
          <img
            src={imageNotFound}
            alt={record.dataIndex}
            style={{ width: "50px", height: "50px" }}
          />
        ),
    },
    { dataIndex: "name", title: "Nama Produk" },
    { dataIndex: "model", title: "Model" },
    { dataIndex: "storage_quantity", title: "Stock" },
    { dataIndex: "diskon", title: "Diskon" },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) =>
        record.status === "0" ? (
          <Tag color="red">Disabled</Tag>
        ) : (
          <Tag color="green">Enabled</Tag>
        ),
    },
    {
      dataIndex: "actions",
      title: "Aksi",
      render: (text, record) => (
        <div className="text-center">
          <CustomButtonGroup
            color={["primary", "danger"]}
            text={["Detail", "Disable"]}
            onClickHandler={[
              () => handleShowDetail(record.product_id),
              () => handleDelete(record.product_id),
            ]}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchProductData({ page: 0, search: "" }));
  }, [dispatch]);

  const handleSearch = () => {
    setCurrentPage(1);
    dispatch(fetchProductData({ page: 0, search: searchTerm }));
  };

  const searchby = [
    {
      value: "judul",
      label: "Judul Buku",
    },
    {
      value: "Kode Buku",
      label: "Kode Buku",
    },
    {
      value: "author",
      label: "Penerbit Buku",
    },
  ];

  const category = [
    {
      value: "Buku",
      label: "Buku",
    },
  ];

  return (
    <Container className="card w-full p-3 gap-3">
      {/* Title */}
      <div>
        <h5 className="text-primary">List Product</h5>
      </div>

      {/* Filter */}
      <div>
        <div className="d-flex gap-2">
          <div>
            <Input
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div>
            <SingleButton color="success" onClick={handleSearch}>
              Search
            </SingleButton>
          </div>
        </div>
      </div>

      {/* Button Group */}
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2">
          <CustomButtonGroup
            color={["primary", "success"]}
            text={["Add Produk", "Mass Upload"]}
            onClickHandler={[handleShowTambah, handleShowMassUpload]}
          />
        </div>
        <div>
          <Button variant="warning" size="sm" className="text-white">
            Bulk Action
          </Button>
        </div>
      </div>

      {/* Table */}
      {loading ? (
        <Skeleton active />
      ) : (
        <TableData columns={columns} dataSource={data} />
      )}

      {/* Pagination */}
      <div className="mt-15 text-center">
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          total={totalRows}
          onChange={handlePageChange}
        />
      </div>

      {/* Aksi Modal */}
      {/* <ModalComponent
        size="xl"
        showModal={showModal}
        handleClose={handleClose}
        title={actionType === "tambah" ? "Tambah Data Produk" : "Mass Upload"}
      >
        {actionType === "tambah" ? <TambahProduk /> : <MassUpload />}
      </ModalComponent> */}
    </Container>
  );
};

export default TableProduk;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBannerData,
  fetchBannerId,
  reorderBannerData,
} from "../../../redux/store/bannerSlice";
import { Button } from "react-bootstrap";
import ModalComponent from "../../part/Modal";
import TambahBanner from "./aksi/TambahBanner";
import UpdateBanner from "./UpdateBanner";
import SingleButton from "../../part/Button";
import axios from "axios";

const ListSlider = () => {
  const dispatch = useDispatch();
  const { data, editData } = useSelector((state) => state.banner);
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("tambah");
  const [sortedBannerIds, setSortedBannerIds] = useState([]);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    const initialSortedIds = data.map((item) => item.id);
    setSortedBannerIds(initialSortedIds);
  }, [data]);

  const handleShowTambah = () => {
    setShowModal(true);
    setActionType("tambah");
  };

  const handleShowDetail = async (bannerId) => {
    setActionType("edit");
    await dispatch(fetchBannerId(bannerId));
    setShowModal(true);
  };

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const saveBannerOrderToLocalStorage = () => {
    localStorage.setItem("bannerOrder", JSON.stringify(sortedBannerIds));
  };

  const handleDrop = (event, targetIndex) => {
    event.preventDefault();
    const sourceIndex = parseInt(event.dataTransfer.getData("text/plain"), 10);
    const updatedIds = [...sortedBannerIds];
    const draggedId = updatedIds.splice(sourceIndex, 1)[0];
    updatedIds.splice(targetIndex, 0, draggedId);
    
    const updatedIdsNumeric = updatedIds.map(id => parseInt(id, 10));
    
    console.log("Updated Sorted Banner IDs:", updatedIdsNumeric);
    setSortedBannerIds(updatedIdsNumeric);
    saveBannerOrderToLocalStorage();
  };
  

  const handleSaveOrder = async () => {
    try {
      const formData = new FormData();
  
      formData.append("sort", JSON.stringify(sortedBannerIds));
  
      const response = await axios.post(
        "https://ujianonline.eurekabookhouse.co.id/marketing/banner_sort",
        formData,
        {
          headers: {
            Authorization: `Bearer qtUPRXyF8NsA3U37wcPNMw==`
          }
        }
      );
      console.log("Response from server:", response.data);
    } catch (error) {
      console.error("Error sending sorted banner IDs:", error);
    }
  };  
  
  
  
  const fetchData = async () => {
    try {
      await dispatch(fetchBannerData());
    } catch (error) {
      console.error("Error fetching data in banner:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  return (
    <div className="container">
      <ModalComponent
        size="xl"
        showModal={showModal}
        handleClose={handleClose}
        title="Upload Banner"
      >
        {actionType === "tambah" ? (
          <TambahBanner
            onSave={() => {
              handleClose();
              fetchData();
            }}
          />
        ) : (
          <UpdateBanner
            bannerData={editData}
            onSave={() => {
              handleClose();
              fetchData();
            }}
          />
        )}
      </ModalComponent>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <p className="text-muted ">
            <span className="text-danger">*</span>Klik Banner Untuk Melihat
            Detail
          </p>
        </div>
        <div>
          <Button variant="primary" size="sm" onClick={handleShowTambah}>
            Tambah
          </Button>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-4">
        {sortedBannerIds.map((bannerId, index) => {
          const item = data.find((item) => item.id === bannerId);
          return (
            <div
              key={item.id}
              draggable
              onDragStart={(event) => handleDragStart(event, index)}
              onDragOver={handleDragOver}
              onDrop={(event) => handleDrop(event, index)}
              className="col mb-4"
            >
              <div
                className="card"
                style={{
                  width: "100%",
                  height: "130px",
                  cursor: "grab",
                  backgroundImage: `url(https://eurekabookhouse.co.id/assets/uplod/slider/${item.slider})`,
                  backgroundSize: "cover",
                  color: "white",
                }}
                onClick={() => handleShowDetail(item.id)}
              >
                <div className="p-3">
                  <p className="text-white">{index + 1}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div>
        <SingleButton
          color="success"
          size="default"
          onClick={handleSaveOrder}
        >
          Simpan Urutan
        </SingleButton>
      </div>
    </div>
  );
};

export default ListSlider;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Input from "../part/Input";
import SelectComponent from "../part/Select";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";

const TambahProduk = () => {
  const navigate = useNavigate();
  const [selectedManufacturerId, setSelectedManufacturerId] = useState("");
  const [selectedSupplierId, setSelectedSupplierId] = useState("");
  const [value, setValue] = useState("");
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [formData, setFormData] = useState({
    model: "",
    sku: "",
    isbn: "",
    id_author: "",
    quantity: "",
    manufacturer_id: "",
    shipping: "",
    price: "",
    points: "",
    diskon: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    storage_quantity: "",
    store_quantity: "",
    id_supply: "",
    name: "",
    description: "",
    meta_description: "",
    meta_keyword: "",
    tag: "",
    quantity: "",
    shipping: "",
  });

  const handleInputChange = (title, inputValue) => {
    setFormData({
      ...formData,
      [title]: inputValue,
    });
  };

  const handleManufacturerChange = (selectedValue) => {
    setSelectedManufacturerId(selectedValue);
    console.log("Selected Manufacturer ID:", selectedValue);
  };

  const handleSupplierChange = (selectedValue) => {
    setSelectedSupplierId(selectedValue);
    console.log("Selected Supplier ID:", selectedValue);
  };

  const handleSubmit = async () => {
    try {
      const formDataObject = new FormData();

      for (const key in formData) {
        formDataObject.append(key, formData[key]);
      }

      formDataObject.append("manufacturer_id", selectedManufacturerId);
      formDataObject.append("id_supply", selectedSupplierId);

      const response = await fetch(
        "https://ujianonline.eurekabookhouse.co.id/master/product_create",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
          },
          body: formDataObject,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Product created successfully:", result);

      navigate("/produk");
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  const fetchSupplierOptions = async () => {
    try {
      const response = await fetch(
        "https://ujianonline.eurekabookhouse.co.id/master/option_supply_get",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setSupplierOptions(result.data);
    } catch (error) {
      console.error("Error fetching supplier options:", error);
    }
  };

  const fetchManufacturerOptions = async () => {
    try {
      const response = await fetch(
        "https://ujianonline.eurekabookhouse.co.id/master/option_manufacturer_get",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setManufacturerOptions(result.data);
    } catch (error) {
      console.error("Error fetching manufacturer options:", error);
    }
  };

  useEffect(() => {
    fetchSupplierOptions();
    fetchManufacturerOptions();
  }, []);

  const layout = [
    {
      value: "1",
      label: "Buku",
    },
    {
      value: "2",
      label: "Sepatu",
    },
    {
      value: "3",
      label: "Umum",
    },
  ];
  return (
    <div>
      <div className="card mb-3 p-3">
        <Container>
          <p className="text-primary fw-bold fs-5">Data Pengirim :</p>
          <Row>
            <Col>
              <Input
                title="Nama Produk"
                value={formData.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
            </Col>
            <Col>
              <Input
                title="Tag Produk"
                value={formData.tag}
                onChange={(e) => handleInputChange("tag", e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="SEO"
                value={formData.seo}
                onChange={(e) => handleInputChange("seo", e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Description Tag Meta"
                value={formData.meta_description}
                onChange={(e) =>
                  handleInputChange("meta_description", e.target.value)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Keyword Tag Meta"
                value={formData.meta_keyword}
                onChange={(e) =>
                  handleInputChange("meta_keyword", e.target.value)
                }
              />
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <ReactQuill theme="snow" onChange={setValue} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="card p-3 mb-3">
        <Container>
          <p className="text-primary fw-bold fs-5">Data :</p>
          <Row>
            <Col>
              <SelectComponent
                label="Supplier"
                option={supplierOptions}
                onChange={handleSupplierChange}
              />
            </Col>
            <Col>
              <SelectComponent
                label="Merk"
                option={manufacturerOptions}
                onChange={handleManufacturerChange}
              />
            </Col>

            <Col>
              <Input
                title="Model"
                value={formData.model}
                onChange={(e) => handleInputChange("model", e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Author"
                value={formData.id_author}
                onChange={(e) => handleInputChange("id_author", e.target.value)}
              />
            </Col>
            <Col>
              <Input
                title="SKU"
                value={formData.sku}
                onChange={(e) => handleInputChange("sku", e.target.value)}
              />
            </Col>
            <Col>
              <Input
                title="ISBN"
                value={formData.isbn}
                onChange={(e) => handleInputChange("isbn", e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="minimum"
                value={formData.quantity}
                onChange={(e) => handleInputChange("quantity", e.target.value)}
              />
            </Col>
            <Col>
              <SelectComponent label="Layout" option={layout} />
            </Col>
            <Col>
              <Input
                title="Harga"
                value={formData.price}
                onChange={(e) => handleInputChange("price", e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Point"
                value={formData.points}
                onChange={(e) => handleInputChange("points", e.target.value)}
              />
            </Col>
            <Col>
              <Input
                title="Diskon"
                value={formData.diskon}
                onChange={(e) => handleInputChange("diskon", e.target.value)}
              />
            </Col>
            <Col>
              <Input
                title="Berat"
                value={formData.weight}
                onChange={(e) => handleInputChange("weight", e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Stok gabungan"
                value={formData.storage_quantity}
                onChange={(e) =>
                  handleInputChange("storage_quantity", e.target.value)
                }
              />
            </Col>
            <Col>
              <Input
                title="Stok Online"
                value={formData.store_quantity}
                onChange={(e) =>
                  handleInputChange("store_quantity", e.target.value)
                }
              />
            </Col>
            <Col>
              <Input title="Toko Kalibata" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input title="Tanggal Tersedia" type="date" />
            </Col>
          </Row>
          <Row>
            <p className="fw-bold">Dimensi :</p>
            <Col>
              <Input
                placeholder="Panjang"
                value={formData.length}
                onChange={(e) => handleInputChange("length", e.target.value)}
              />
            </Col>
            <Col>
              <Input
                placeholder="Lebar"
                value={formData.width}
                onChange={(e) => handleInputChange("width", e.target.value)}
              />
            </Col>
            <Col>
              <Input
                placeholder="Tinggi"
                value={formData.height}
                onChange={(e) => handleInputChange("height", e.target.value)}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Row>
        <Col>
          <Button
            variant="success"
            className="w-100 mb-3"
            onClick={handleSubmit}
          >
            Simpan
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TambahProduk;

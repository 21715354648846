// store/store.js
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import authorReducer from "./authorSlice";
import merekReducer from "./merekSlice";
import categoryReducer from "./categorySlice";
import couponReducer from "./couponSlice";
import orderReducer from "./orderSlice";
import productReducer from "./productSlice";
import promoReduces from "./promoSlice";
import reviewReducer from "./reviewSlice";
import bannerReducer from "./bannerSlice";
import authReducer from "./authSlice";

import categoryOptionReducer from "./option/categoryOptionSlice";

import dashboardReducer from "./report/dashboardSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    author: authorReducer,
    merek: merekReducer,
    category: categoryReducer,
    coupon: couponReducer,
    order: orderReducer,
    product: productReducer,
    promo: promoReduces,
    review: reviewReducer,
    auth: authReducer,
    banner: bannerReducer,

    categoryOption: categoryOptionReducer,

    dashboard: dashboardReducer,
  },
});

export default store;

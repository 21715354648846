// store/categorySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryService from "../services/categoryService";

export const fetchCategoryData = createAsyncThunk(
  "category/fetchCategoryData",
  async (page = 0) => {
    const response = await categoryService.fetchCategoryData(page);
    return response;
  }
);

export const fetchCategoryId = createAsyncThunk(
  "category/fetchCategoryId",
  async (categoryId) => {
    const response = await categoryService.fetchCategoryId(categoryId);
    console.log("Category data:", response.data);
    return response.data;
  }
);

export const updateCategoryData = createAsyncThunk(
  "category/updateCategoryData",
  async (categoryData) => {
    const response = await categoryService.updateCategory(categoryData);
    return response.data;
  }
);

export const createCategoryData = createAsyncThunk(
  "category/createCategoryData",
  async (categoryData) => {
    const response = await categoryService.createCategory(categoryData);
    return response.data;
  }
);

export const deleteCategoryData = createAsyncThunk(
  "category/deleteCategoryData",
  async (categoryId) => {
    const response = await categoryService.deleteCategory(categoryId);
    return response.data;
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    data: [],
    editData: {},
    loading: false,
    editLoading: false,
    totalRows: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoryData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalRows = action.payload.totalRow || action.payload.total;
        console.log("Total Rows:", state.totalRows);
        state.loading = false;
      })
      .addCase(fetchCategoryId.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchCategoryId.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      })
      .addCase(updateCategoryData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(createCategoryData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(deleteCategoryData.fulfilled, (state, action) => {
        console.log(action.payload);
      });
  },
});

export default categorySlice.reducer;

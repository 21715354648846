import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import Input from "../part/Input";
import SelectComponent from "../part/Select";
import TextArea from "../part/TextArea";
import Upload from "../part/Upload";
import promoService from "../../redux/services/promoService";
import ReactSelect from "../part/ReactSelect";
import { fetchOptionCategory } from "../../redux/store/option/categoryOptionSlice";

const AksiPromo = ({ promoData, isAdding, onSave }) => {
  useEffect(() => {
    if (!promoData) {
      return "loading";
    }
  }, [promoData]);
  const dispatch = useDispatch();
  const [updatedData, setUpdatedData] = useState(promoData);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSave = async () => {
    try {
      if (isAdding) {
        await promoService.createPromo(updatedData);
      } else {
        await promoService.updatePromo(updatedData);
      }
      onSave();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  //     const fetchData = async () => {
  //       try {
  //         const response = await axios.get(
  //           "https://ujianonline.eurekabookhouse.co.id/master/option_category_get",
  //           {
  //             headers: {
  //               Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
  //             },
  //           }
  //         );

  //         const judulPromoOptions = response.data.data.map((promo) => ({
  //           value: promo.value,
  //           label: promo.label,
  //         }));

  //         setJudulPromoOptions(judulPromoOptions);

  //         // Find the option with id_promo_kategori matching the selectCatgeoryId
  //         const selectedOption = judulPromoOptions.find(
  //           (option) => option.value === selectCatgeoryId
  //         );

  //         // If a selected option is found, update the selectedBerlakuPada state
  //         if (selectedOption) {
  //           setSelectedBerlakuPada(selectedOption);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     };

  //     fetchData();
  //   }, [selectCatgeoryId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChangeAPI = (name, selectedOption) => {
    console.log(`Selected ${name}:`, selectedOption.value);

    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : null,
    }));
  };

  const optionCategory = useSelector((state) => state.categoryOption.data);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setUpdatedData({
      ...promoData,
      image: file,
    });
  };

  useEffect(() => {
    dispatch(fetchOptionCategory());
  }, [dispatch]);

  const opsiBerlaku = [
    {
      value: "semua",
      label: "Semua",
    },
    {
      value: "web",
      label: "Web",
    },
    {
      value: "android",
      label: "Android",
    },
    {
      value: "ios",
      label: "IOS",
    },
    {
      value: "app",
      label: "App",
    },
  ];

  const boolKlaim = [
    {
      value: 0,
      label: "Tidak",
    },
    {
      value: 1,
      label: "Ya",
    },
  ];

  const defaultOpsiBerlaku = opsiBerlaku.find(
    (option) => option.value === promoData.berlaku_di
  );

  const defaultStatusOption = boolKlaim.find(
    (option) => option.value === parseInt(promoData.klaim_bersyarat, 10)
  );

  return (
    <div className="card p-3">
      <Container>
        <Row>
          <Col>
            <ReactSelect
              label="Kategori"
              options={optionCategory}
              onChange={(selectedOption) =>
                handleSelectChangeAPI("id_promo_kategori", selectedOption)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Judul Promo"
              value={updatedData.judul_promo}
              onChange={handleChange}
              name="judul_promo"
            />
          </Col>
          <Col>
            <Input
              title="Slug"
              value={updatedData.slug}
              onChange={handleChange}
              name="slug"
            />
          </Col>
          <Col>
            <Input
              title="Subjudul Promo"
              value={updatedData.subjudul_promo}
              onChange={handleChange}
              name="subjudul_promo"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Minimum Pembelian"
              value={updatedData.minimum_pembelian}
              onChange={handleChange}
              name="minimum_pembelian"
              type="number"
            />
          </Col>
          <Col>
            <ReactSelect
              defaultValue={defaultOpsiBerlaku}
              label="Berlaku Di"
              options={opsiBerlaku}
              onChange={(selectedOption) =>
                handleSelectChange("berlaku_di", selectedOption.value)
              }
              value={opsiBerlaku.find(
                (option) => option.value === updatedData.berlaku_di
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Mulai Berlaku"
              type="date"
              value={updatedData.berlaku_mulai}
              onChange={handleChange}
              name="berlaku_mulai"
            />
          </Col>
          <Col>
            <Input
              title="Berlaku Sampai"
              type="date"
              value={updatedData.berlaku_sampai}
              onChange={handleChange}
              name="berlaku_sampai"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              title="Syarat Ketentuan"
              content={updatedData.syarat_ketentuan}
              onChange={handleChange}
              name="syarat_ketentuan"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactSelect
              defaultValue={defaultStatusOption}
              label="Status"
              options={boolKlaim}
              onChange={(selectedOption) =>
                handleSelectChange("klaim_bersyarat", selectedOption.value)
              }
              value={boolKlaim.find(
                (option) => option.value === updatedData.klaim_bersyarat
              )}
            />
          </Col>
          <Col>
            <Upload title="Gambar" onChange={handleImageChange} />
          </Col>
        </Row>
      </Container>
      <div>
        <Button variant="success" onClick={handleSave}>
          {isAdding ? "Tambah Manufacture" : "Update Manufacture"}
        </Button>
      </div>
    </div>
  );
};

export default AksiPromo;

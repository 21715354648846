import api from "../utils/api";

const authorService = {
  fetchAuthorData: async (page) => {
    const start = page * 10;
    const length = 10;
    return await api.get(`/master/author_get?start=${start}&length=${length}`);
  },

  fetchAuthorId: async (authorId) => {
    return await api.get(`/master/author_id_get/${authorId}`);
  },

  updateAuthor: async (authorData) => {
    const formData = new FormData();
    formData.append("id_author", authorData?.id_author);
    formData.append("nama_author", authorData.nama_author);
    formData.append("birthday_author", authorData.birthday_author);
    formData.append("description_author", authorData.description_author);

    return await api.post("/master/author_update", formData);
  },

  createAuthor: async (authorData) => {
    const formData = new FormData();
    formData.append("nama_author", authorData.nama_author);
    formData.append("birthday_author", authorData.birthday_author);
    formData.append("description_author", authorData.description_author);

    return await api.post("/master/author_create", formData);
  },

  deleteAuthor: async (authorId) => {
    const formData = new FormData();
    formData.append("id_author", authorId);

    return await api.post("/master/author_delete", formData);
  },
};

export default authorService;

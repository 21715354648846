import React from "react";
import Select from "react-select";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const ReactSelect = ({
  defaultValue,
  options,
  onChange,
  label,
  value,
  placeholder = "Select...",
}) => {
  const selectedValue =
    typeof value === "object"
      ? value
      : options && options.length > 0
      ? options.find((option) => option.value === value)
      : null;

  return (
    <div>
      {label && <label className="mb-2">{label}</label>}
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
        options={options}
        formatGroupLabel={formatGroupLabel}
        onChange={onChange}
        value={selectedValue}
        aria-label={label}
      />
    </div>
  );
};

export default ReactSelect;

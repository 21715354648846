// Tabs.js
import React from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const TabsComponent = ({ children }) => (
  <Tabs defaultActiveKey="1" centered>
    {children}
  </Tabs>
);

export { TabsComponent, TabPane };

import React from "react";
import TablePromo from "../../../components/promo/TablePromo";

const Promo = () => {
  return (
    <div>
      <TablePromo />
    </div>
  );
};

export default Promo;

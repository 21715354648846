import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Input from "../part/Input";
import SelectComponent from "../part/Select";
import TextArea from "../part/TextArea";
import { Rate } from "antd";
import ReactSelect from "../part/ReactSelect";

const DetailReview = ({ reviewData }) => {
  useEffect(() => {
    if (!reviewData) {
      return "loading";
    }
  }, [reviewData]);

  const boolStatus = [
    {
      value: 0,
      label: "Tidak Aktif",
    },
    {
      value: 1,
      label: "Aktif",
    },
  ];

  const handleSelectChange = (name, value) => {
    reviewData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const defaultStatusOption = boolStatus.find(
    (option) => option.value === parseInt(reviewData.status, 10)
  );

  const [value, setValue] = useState(3);

  const desc = ["terrible", "bad", "normal", "good", "wonderful"];

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Input title="Produk" value={reviewData.text} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input title="Author" value={reviewData.author} />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea title="Text" content={reviewData.text} />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="mb-2">Rating</p>
            <Rate
              tooltips={desc}
              onChange={setValue}
              value={reviewData.rating}
            />
            {reviewData.rating ? (
              <span>{desc[reviewData.rating - 1]}</span>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactSelect
              defaultValue={defaultStatusOption}
              label="Status"
              options={boolStatus}
              onChange={(selectedOption) =>
                handleSelectChange("status", selectedOption.value)
              }
              value={boolStatus.find(
                (option) => option.value === reviewData.status
              )}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DetailReview;

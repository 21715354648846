import React from "react";
import { Form } from "react-bootstrap";

const SelectComponent = ({
  label,
  option,
  defaultValue,
  onChange,
  initialValue,
}) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    if (onChange) {
      const finalValue =
        selectedValue && selectedValue.value !== undefined
          ? selectedValue.value
          : selectedValue;

      onChange(finalValue);
    }
  };

  return (
    <Form.Group className="mb-3">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        aria-label="Default select example"
        defaultValue={defaultValue}
        onChange={handleSelectChange}
        initialValue={initialValue}
      >
        <option value="" disabled hidden>
          Select an option
        </option>
        {option.map((opt, index) => (
          <option key={index} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default SelectComponent;

import React from "react";
import TableKategori from "../../../components/kategori/TableKategori";

const Kategori = () => {
  return (
    <>
      <TableKategori />
    </>
  );
};

export default Kategori;

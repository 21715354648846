import React from "react";
import { Table } from "antd";

const TableData = (props) => {
  const { columns, dataSource, onChange, footer, backgroundColor } = props;

  const components = {
    header: {
      cell: (props) => {
        return (
          <th
            style={{
              backgroundColor: backgroundColor ? backgroundColor : "#0d6efd",
              color: "#fff",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {props.children}
          </th>
        );
      },
    },
  };

  return (
    <div className="card w-full">
      <div className="overflow-x-auto">
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={onChange}
          pagination={false}
          components={components}
          footer={footer}
        />
      </div>
    </div>
  );
};

export default TableData;

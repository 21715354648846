import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Sidebar from "./components/navbar/Sidebar";
import Header from "./components/navbar/Header";
import Content from "./components/navbar/Content";

import Login from "./pages/auth/Login";

import Dashboard from "./pages/main-navigation/Dashboard";
import Pesanan from "./pages/main-navigation/Pesanan";
import DetailPesanan from "./components/pesanan/DetailPesanan";
import Produk from "./pages/master/katalog/Produk";
import DetailProduk from "./components/produk/DetailProduk";
import Merek from "./pages/master/katalog/Merek";
import Author from "./pages/master/katalog/Author";
import Kategori from "./pages/master/katalog/Kategori";
import EditKategori from "./components/kategori/EditKategori";
import User from "./pages/master/katalog/User";
import Review from "./pages/master/Review";
import Banner from "./pages/master/marketing/Banner";
import Promo from "./pages/master/marketing/Promo";
import Kupon from "./pages/master/marketing/Kupon";
import TambahProduk from "./components/produk/TambahProduk";

const App = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isSidebarHoverable, setSidebarHoverable] = useState(false);
  const [isDarkMode, setDarkMode] = useState(false);

  const handleSidebarOpen = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(true);
    setSidebarHoverable(true);
  };

  const handleSidebarExpand = () => {
    setSidebarOpen(false);
    setSidebarHoverable(false);
  };

  const handleSidebarHover = () => {
    if (isSidebarHoverable) {
      setSidebarOpen(false);
    }
  };

  const handleSidebarLeave = () => {
    if (isSidebarHoverable) {
      setSidebarOpen(true);
    }
  };

  const handleDarkLightToggle = () => {
    setDarkMode(!isDarkMode);
  };

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate]);

  const isLoginPage = window.location.pathname === "/login";

  if (isLoginPage) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
      </Routes>
    );
  }

  return (
    <>
      <Header
        handleSidebarOpen={handleSidebarOpen}
        isDarkMode={isDarkMode}
        handleDarkLightToggle={handleDarkLightToggle}
      />

      <Sidebar
        isSidebarOpen={isSidebarOpen}
        isSidebarHoverable={isSidebarHoverable}
        handleSidebarHover={handleSidebarHover}
        handleSidebarLeave={handleSidebarLeave}
        handleSidebarExpand={handleSidebarExpand}
        handleSidebarClose={handleSidebarClose}
      />

      <Content isSidebarOpen={isSidebarOpen}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/pesanan" element={<Pesanan />} />
          <Route path="/produk" element={<Produk />} />
          <Route path="/kategori" element={<Kategori />} />
          <Route path="/merek" element={<Merek />} />
          <Route path="/author" element={<Author />} />
          <Route path="/user" element={<User />} />

          <Route path="/review" element={<Review />} />

          <Route path="/marketing/banner" element={<Banner />} />
          <Route path="/marketing/promo" element={<Promo />} />
          <Route path="/marketing/kupon" element={<Kupon />} />

          <Route path="/pesanan/detail/:orderId" element={<DetailPesanan />} />
          <Route path="/produk/detail/:productId" element={<DetailProduk />} />
          <Route
            path="/category/detail/:categoryId"
            element={<EditKategori />}
          />
          <Route path="/produk/tambah" element={<TambahProduk />} />
        </Routes>
      </Content>
    </>
  );
};

export default App;

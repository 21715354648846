// store/authorSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authorService from "../services/authorService";

export const fetchAuthorData = createAsyncThunk(
  "author/fetchAuthorData",
  async (page = 0) => {
    const response = await authorService.fetchAuthorData(page);
    return response;
  }
);

export const fetchAuthorId = createAsyncThunk(
  "author/fetchAuthorId",
  async (userId) => {
    const response = await authorService.fetchAuthorId(userId);
    console.log("Author data:", response.data);
    return response.data;
  }
);

export const updateAuthorData = createAsyncThunk(
  "author/updateAuthorData",
  async (authorData) => {
    const response = await authorService.updateAuthor(authorData);
    return response.data;
  }
);

export const createAuthorData = createAsyncThunk(
  "author/createAuthorData",
  async (authorData) => {
    const response = await authorService.createAuthor(authorData);
    return response.data;
  }
);

export const deleteAuthorData = createAsyncThunk(
  "author/deleteAuthorData",
  async (authorId) => {
    const response = await authorService.deleteAuthor(authorId);
    return response.data;
  }
);

const authorSlice = createSlice({
  name: "author",
  initialState: {
    data: [],
    editData: {},
    loading: false,
    editLoading: false,
    totalRows: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthorData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAuthorData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalRows = action.payload.totalRow || action.payload.total;
        console.log("Total Rows:", state.totalRows);
        state.loading = false;
      })
      .addCase(fetchAuthorId.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchAuthorId.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      })
      .addCase(updateAuthorData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(createAuthorData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(deleteAuthorData.fulfilled, (state, action) => {
        console.log(action.payload);
      });
  },
});

export default authorSlice.reducer;

// store/bannerSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bannerService from "../services/bannerService";

export const fetchBannerData = createAsyncThunk(
  "banner/fetchBannerData",
  async () => {
    const response = await bannerService.fetchBannerData();
    // Mengonversi id dari data banner menjadi angka
    const data = response.data.map(item => ({
      ...item,
      id: parseInt(item.id, 10)
    }));
    return data;
  }
);


export const fetchBannerId = createAsyncThunk(
  "banner/fetchBannerId",
  async (bannerId) => {
    const response = await bannerService.fetchBannerId(bannerId);
    console.log("Banner data:", response.data);
    return response.data;
  }
);

export const updateBannerData = createAsyncThunk(
  "banner/updateBannerData",
  async (bannerData) => {
    const response = await bannerService.updateBanner(bannerData);
    return response.data;
  }
);

export const createBannerData = createAsyncThunk(
  "banner/createBannerData",
  async (bannerData) => {
    const response = await bannerService.createBanner(bannerData);
    return response.data;
  }
);

export const reorderBannerData = (updatedData) => {
  return {
    type: "banner/reorderBannerData",
    payload: updatedData,
  };
};

// export const deleteBannerData = createAsyncThunk(
//   "banner/deleteBannerData",
//   async (bannerId) => {
//     const response = await bannerService.deleteBannerData(bannerId);
//     return response.data;
//   }
// );

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    data: [],
    editData: {},
    loading: false,
    editLoading: false,
  },
  reducers: {
    reorderBannerData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannerData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBannerData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchBannerId.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchBannerId.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      })
      .addCase(updateBannerData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(createBannerData.fulfilled, (state, action) => {
        console.log(action.payload);
      });
    // .addCase(deleteBannerData.fulfilled, (state, action) => {
    //   console.log(action.payload);
    // });
  },
});

export default bannerSlice.reducer;

import React from "react";
import TableProduk from "../../../components/produk/TableProduk";

const Produk = () => {
  return (
    <div>
      <TableProduk />
    </div>
  );
};

export default Produk;

import React from "react";
import TableAuthor from "../../../components/author/TableAuthor";

const Author = () => {
  return (
    <div>
      <TableAuthor />
    </div>
  );
};

export default Author;

import React from "react";
import TableUser from "../../../components/users/TableUser";

const User = () => {
  return (
    <div>
      <TableUser />
    </div>

    // <div className="card w-full p-3">
    //   <div className="overflow-x-auto">
    //     <TableData />
    //   </div>
    // </div>
  );
};

export default User;

import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Upload from "../part/Upload";
import Input from "../part/Input";
import ReactSelect from "../part/ReactSelect";
import merekService from "../../redux/services/merekService";
import TextArea from "../part/TextArea";

const AksiMerek = ({ merekData, isAdding, onSave }) => {
  useEffect(() => {
    if (!merekData) {
      return "loading";
    }
  }, [merekData]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [updatedData, setUpdatedData] = useState(merekData);

  const handleUpdateMerek = async () => {
    try {
      if (isAdding) {
        await merekService.createMerek(updatedData);
      } else {
        await merekService.updateMerek(updatedData);
      }
      onSave();
    } catch (error) {
      console.error("Error updating manufacturer:", error);
    }
  };

  const handleSelectChange = (name, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setUpdatedData({
      ...merekData,
      image: file,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const boolOption = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const boolStatus = [
    {
      value: 0,
      label: "Tidak Aktif",
    },
    {
      value: 1,
      label: "Aktif",
    },
  ];

  const defaultStatusOption = boolStatus.find(
    (option) => option.value === parseInt(merekData.status, 10)
  );

  const defaultOptionOfficial = boolOption.find(
    (option) => option.value === parseInt(merekData.official, 10)
  );

  const defaultOptionReseller = boolOption.find(
    (option) => option.value === parseInt(merekData.reseller, 10)
  );

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Row>
              <Col>
                <Upload title="Image" onChange={handleImageChange} />
              </Col>
            </Row>
            <Row>
              {/* Preview Image */}
              <Col>
                <div
                  className="card mb-3"
                  style={{ width: "150px", height: "150px" }}
                >
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={7}>
            <Row>
              <Col>
                <Input
                  title="Nama Manufacture"
                  value={updatedData.name}
                  onChange={handleChange}
                  name="name"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  title="Description"
                  content={updatedData.description}
                  onChange={handleChange}
                  name="description"
                />
              </Col>
            </Row>
          </Col>
          <Row>
            <Col>
              <Input
                title="Address"
                value={updatedData.address}
                onChange={handleChange}
                name="address"
              />
            </Col>
            <Col>
              <Input
                title="Owner"
                value={updatedData.owner}
                onChange={handleChange}
                name="owner"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Phone"
                value={updatedData.phone}
                onChange={handleChange}
                name="phone"
              />
            </Col>
            <Col>
              <Input
                title="Email"
                value={updatedData.email}
                onChange={handleChange}
                name="email"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Bank Name"
                value={updatedData.bank_name}
                onChange={handleChange}
                name="bank_name"
              />
            </Col>
            <Col>
              <Input
                title="Bank Number"
                value={updatedData.bank_number}
                onChange={handleChange}
                name="bank_number"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ReactSelect
                defaultValue={defaultOptionOfficial}
                label="Official"
                options={boolStatus}
                onChange={(selectedOption) =>
                  handleSelectChange("official", selectedOption.value)
                }
                value={boolOption.find(
                  (option) => option.value === updatedData.official
                )}
              />
            </Col>
            <Col>
              <ReactSelect
                defaultValue={defaultOptionReseller}
                label="Reseller"
                options={boolStatus}
                onChange={(selectedOption) =>
                  handleSelectChange("reseller", selectedOption.value)
                }
                value={boolOption.find(
                  (option) => option.value === updatedData.reseller
                )}
              />
            </Col>
            <Col>
              <ReactSelect
                defaultValue={defaultStatusOption}
                label="Status"
                options={boolStatus}
                onChange={(selectedOption) =>
                  handleSelectChange("status", selectedOption.value)
                }
                value={boolStatus.find(
                  (option) => option.value === updatedData.status
                )}
              />
            </Col>
          </Row>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button variant="success" onClick={handleUpdateMerek}>
              {isAdding ? "Tambah Manufacture" : "Update Manufacture"}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AksiMerek;

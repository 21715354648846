import React, { useState, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import TableData from "../part/Table";

const gridStyle = { minHeight: 250, marginTop: 10 };

const columns = [
  {
    dataIndex: "id",
    title: "No",
    render: (text, record, index) => index + 1,
  },
  { dataIndex: "name", title: "Nama Product" },
  { dataIndex: "sum_total", title: "Jumlah Total" },
  { dataIndex: "image", title: "Image" },
  { dataIndex: "sum_qty", title: "QTY" },
];

const TableMediaOrder = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ujianonline.eurekabookhouse.co.id/report/dashboard_get",
          {
            headers: {
              Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
            },
          }
        );

        const result = await response.json();

        if (result.success) {
          setData(result.data.top10_product);
        } else {
          console.error("Failed to fetch data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="card w-full p-3 mb-3">
      <div className="mb-3 text-primary fs-5 fw-bold">TOP 10 Product :</div>
      <TableData columns={columns} dataSource={data} />
    </div>
  );
};

export default TableMediaOrder;

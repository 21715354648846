import React from "react";
import { TabsComponent, TabPane } from "../part/Tabs";
import SliderDnd from "./tab-banner/SliderDnd";
import UpdateBanner from "./tab-banner/UpdateBanner";
import HorizontalBanner from "./tab-banner/HorizontalBanner";
import ListSlider from "./tab-banner/ListSlider";

const BannerSlider = () => {
  return (
    <div className="card w-full p-3">
      <TabsComponent>
        <TabPane tab="Slider" key="1">
          <ListSlider />
        </TabPane>
        {/* <TabPane tab="Horizontal banner" key="3">
          <HorizontalBanner />
        </TabPane> */}
      </TabsComponent>
    </div>
  );
};

export default BannerSlider;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoryData,
  fetchCategoryId,
} from "../../redux/store/categorySlice";
import "@inovua/reactdatagrid-community/index.css";
import { Button } from "react-bootstrap";
import ModalComponent from "../part/Modal";
import TambahKategori from "./TambahKategori";
import EditKategori from "./EditKategori";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import imageNotFound from "../../images/notavailable.png";
import { Col, Pagination, Row, Skeleton, Tag } from "antd";
import TableData from "../part/Table";
import SingleButton from "../part/Button";

const TableKategori = () => {
  const dispatch = useDispatch();
  const { data, loading, editData, totalRows } = useSelector(
    (state) => state.category
  );
  const gridStyle = { minHeight: 500, marginTop: 10 };

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("tambah");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleShowTambah = () => {
    setShowModal(true);
    setActionType("tambah");
  };

  const handleShowDetail = async (categoryId) => {
    setActionType("edit");
    await dispatch(fetchCategoryId(categoryId));
    navigate(`/category/detail/${categoryId}`, {
      state: { categoryData: editData },
    });
    setSelectedCategoryId(categoryId);
    console.log("Selected category ID:", categoryId);
  };

  const handleClose = () => setShowModal(false);

  const columns = [
    {
      dataIndex: "id",
      title: "No",
      render: (text, record, index) => index + 1 + (currentPage - 1) * 10,
    },
    {
      dataIndex: "image",
      title: "Cover",
      render: (text, record) =>
        record.image ? (
          <img
            src={`https://eurekabookhouse.co.id/assets/front/images/icons/nerd/${record.image}`}
            alt={record.name}
            style={{ width: "50px", height: "50px" }}
          />
        ) : (
          <img
            src={imageNotFound}
            alt={record.name}
            style={{ width: "50px", height: "50px" }}
          />
        ),
    },
    { dataIndex: "name", title: "Kategori" },
    { dataIndex: "seo", title: "SEO" },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) =>
        record.status === "0" ? (
          <Tag color="red">Disabled</Tag>
        ) : (
          <Tag color="green">Enabled</Tag>
        ),
    },
    {
      dataIndex: "actions",
      title: "Aksi",
      render: (text, record) => (
        <SingleButton
          color="primary"
          size="sm"
          onClick={() => handleShowDetail(record.category_id)}
          block
        >
          Detail
        </SingleButton>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchCategoryData(page - 1));
  };

  useEffect(() => {
    dispatch(fetchCategoryData());
  }, [dispatch]);

  const handleExport = () => {
    const headersToExport = columns.map((column) => column.header);
    const filteredHeadersToExport = headersToExport.filter(
      (header) => header !== "Aksi"
    );

    const dataToExport = data.map((item) => {
      const exportedItem = {};
      filteredHeadersToExport.forEach((header) => {
        exportedItem[header] = item[header.toLowerCase()]; //
      });
      return exportedItem;
    });

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "exported_data_kategori.xlsx");
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="card w-full p-3 mb-3">
          <div className="d-flex justify-content-between">
            <h5 className="text-primary">List Kategori</h5>
            <div>
              <Button variant="success" size="sm" onClick={handleExport}>
                Export
              </Button>
            </div>
          </div>
          <div>
            <Button variant="success" size="sm" onClick={handleShowTambah}>
              Tambah Kategori
            </Button>
          </div>
          <ModalComponent
            size="xl"
            showModal={showModal}
            handleClose={handleClose}
            title={
              actionType === "tambah"
                ? "Tambah Data Kategori"
                : "Detail Data Kategori"
            }
          >
            {actionType === "tambah" ? (
              <TambahKategori
                onSave={() => {
                  handleClose();
                  dispatch(fetchCategoryData());
                }}
              />
            ) : (
              <EditKategori
                categoryData={editData}
                onSave={() => {
                  handleClose();
                  dispatch(fetchCategoryData());
                }}
              />
            )}
          </ModalComponent>
          <div className="mt-3">
            <TableData columns={columns} dataSource={data} />
          </div>
          <div style={{ marginTop: 16, textAlign: "center" }}>
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={totalRows}
              onChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TableKategori;

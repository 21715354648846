import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Input from "../../part/Input";
import SingleButton from "../../part/Button";

const AlamatKirim = ({ detailData }) => {
  return (
    <div className="card mb-3 p-3">
      <Container>
        <div className="d-flex justify-content-between mb-3">
          <p className="text-primary fw-bold fs-5">Detail Data Shipment :</p>
        </div>
        <Row>
          <Col>
            <Input
              title="First Name :"
              value={detailData.shipping_firstname}
              disabled={true}
            />
          </Col>
          <Col>
            <Input
              title="Last Name :"
              value={detailData.shipping_lastname}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Shipping Method :"
              value={detailData.shipping_method}
              disabled={true}
            />
          </Col>
          <Col>
            <Input
              title="Shipping Phone :"
              value={detailData.telephone}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Address 1 :"
              value={detailData.shipping_address_1}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Address 2 :"
              value={detailData.shipping_address_2}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Kecamatan :"
              value={detailData.shipping_city}
              disabled={true}
            />
          </Col>
          <Col>
            <Input
              title="Kota :"
              value={detailData.shipping_zone}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Provinsi :"
              value={detailData.shipping_country}
              disabled={true}
            />
          </Col>
          <Col>
            <Input
              title="Pos Code :"
              value={detailData.shipping_postcode}
              disabled={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AlamatKirim;

import React from "react";
import { Form } from "react-bootstrap";

const Upload = ({ title, size = "sm", onChange }) => {
  const handleFileChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div>
      <Form.Group controlId="formFileSm" className="mb-3">
        <Form.Label>{title}</Form.Label>
        <Form.Control type="file" size={size} onChange={handleFileChange} />
      </Form.Group>
    </div>
  );
};

export default Upload;

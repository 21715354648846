// store/orderSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "../services/orderService";

export const fetchOrderData = createAsyncThunk(
  "order/fetchOrderData",
  async ({ page = 0, status = "" } = {}) => {
    const response = await orderService.fetchOrderData(page, status);
    return response;
  }
);

export const fetchAllOrderData = createAsyncThunk(
  "order/fetchAllOrderData",
  async (year) => {
    try {
      const response = await orderService.fetchAllOrderData(year);
      return response;
    } catch (error) {
      console.error("Error fetching all data:", error);
      throw error;
    }
  }
);

export const fetchOrderId = createAsyncThunk(
  "order/fetchOrderId",
  async (orderId) => {
    const response = await orderService.fetchOrderId(orderId);
    return response.data;
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    data: [],
    allData: [],
    loading: false,
    editLoading: false,
    totalRows: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrderData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalRows = action.payload.totalRow || action.payload.total;
        console.log("Total Rows:", state.totalRows);
        state.loading = false;
      })
      .addCase(fetchAllOrderData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllOrderData.fulfilled, (state, action) => {
        state.allData = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchOrderId.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchOrderId.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      });
  },
});

export default orderSlice.reducer;

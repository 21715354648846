// components/User/TableUser.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData, fetchUserEditData } from "../../redux/store/userSlice";
import { Col, Row, Button, Container } from "react-bootstrap";
import ModalComponent from "../part/Modal";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Input from "../part/Input";
import EditUser from "./EditUser";
import TambahUser from "./TambahUser";
import CustomButtonGroup from "../part/GroupButton";
import { Pagination, Skeleton } from "antd";
import TableData from "../part/Table";
import SingleButton from "../part/Button";

const TableUser = () => {
  const dispatch = useDispatch();
  const { data, loading, editData, totalRows } = useSelector(
    (state) => state.user
  );

  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("tambah");
  const [selectedMerekId, setSelectedMerekId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleShowTambah = () => {
    setShowModal(true);
    setActionType("tambah");
  };

  const handleShowEdit = (userId) => {
    setShowModal(true);
    setActionType("edit");
    setSelectedMerekId(userId);
    console.log("Selected manufacture ID:", userId);
    dispatch(fetchUserEditData(userId));
  };

  const handleClose = () => setShowModal(false);

  const columns = [
    {
      title: "No",
      dataIndex: "user_id",
      render: (text, record, index) => index + 1 + (currentPage - 1) * 10,
    },
    {
      title: "Username",
      dataIndex: "username",
      sorter: (a, b) => a.username - b.username,
    },
    {
      title: "First Name",
      dataIndex: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Date Added",
      dataIndex: "date_added",
    },
    {
      title: "Aksi",
      dataIndex: "user_id",
      render: (text, record) => (
        <SingleButton
          color="primary"
          size="sm"
          block
          onClick={() => handleShowEdit(record.user_id)}
        >
          Edit
        </SingleButton>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchUserData(page - 1));
  };

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  return (
    <div className="card w-full p-3 mb-3">
      <h5 className="text-primary">List User</h5>
      <ModalComponent
        size="xl"
        showModal={showModal}
        handleClose={handleClose}
        title={actionType === "tambah" ? "Tambah Data User" : "Edit Data User"}
      >
        {actionType === "tambah" ? (
          <TambahUser />
        ) : (
          <EditUser userData={editData} />
        )}
      </ModalComponent>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2">
          <Input placeholder="Cari ..." />
        </div>
        <div>
          <SingleButton
            color="success"
            size="sm"
            block
            onClick={handleShowTambah}
          >
            Tambah
          </SingleButton>
        </div>
      </div>

      <Row>
        <Col>
          {loading ? (
            <Skeleton active />
          ) : (
            <TableData columns={columns} dataSource={data} />
          )}
          <div style={{ marginTop: 16, textAlign: "center" }}>
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={totalRows}
              onChange={handlePageChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TableUser;

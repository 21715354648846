import React from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";

import TableData from "../../part/Table";
import Input from "../../part/Input";
import SingleButton from "../../part/Button";

const Total = ({ detailData }) => {
  const formatCurrency = (amount) => {
    if (amount === undefined || amount === null) {
      return "";
    }
    return amount
      .toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
      .replace(",00", "");
  };

  const columnsProduk = [
    { dataIndex: "name", title: "Produk" },
    { dataIndex: "model", title: "Model" },
    { dataIndex: "quantity", title: "Qty" },
    {
      dataIndex: "price",
      title: "Price",
      render: (price) => formatCurrency(parseFloat(price)),
    },
    { dataIndex: "diskon", title: "Diskon" },
    { dataIndex: "tax", title: "Pajak" },
    {
      dataIndex: "total",
      title: "Total",
      render: (total) => formatCurrency(parseFloat(total)),
    },
  ];

  const getSubtotal = () => {
    let subtotal = 0;
    if (detailData && detailData.product_order) {
      detailData.product_order.forEach((item) => {
        subtotal += parseFloat(item.total);
      });
    }
    return subtotal;
  };

  const footer = () => {
    const subtotal = getSubtotal();
    return (
      <Container className="d-flex justify-content-end">
        <div className="card w-25 p-3">
          <table>
            <tr>
              <th>Subtotal</th>
              <th>:</th>
              <th>{formatCurrency(subtotal)}</th>
            </tr>
            <tr>
              <th>{detailData.shipping_method}</th>
              <th>:</th>
              <th>{formatCurrency(detailData.ongkoskirim)}</th>
            </tr>
            <tr>
              <th>Free Ongkir</th>
              <th>:</th>
              <th>{formatCurrency(detailData.freeongkir)}</th>
            </tr>
            <tr>
              <th>Total</th>
              <th>:</th>
              <th>{formatCurrency(detailData.total)}</th>
            </tr>
          </table>
        </div>
      </Container>
    );
  };

  return (
    <div className="card p-2 mb-3">
      <Container className="mb-3">
        <p className="text-primary fw-bold fs-5">Detail Data Total :</p>
        <TableData
          columns={columnsProduk}
          dataSource={detailData.product_order || []}
          footer={footer}
          backgroundColor="#28a745"
        />

        <Container className="card p-2 mt-3">
          <h5>Detail Payment :</h5>
          <Row>
            <Col>
              <Input
                title="Payment Order :"
                value={detailData.payment_id}
                disabled={true}
              />
            </Col>
            <Col>
              <Input
                title="Payment Name :"
                disabled={true}
                value={
                  detailData.payment_firstname +
                  " " +
                  detailData.payment_lastname
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Payment Telp :"
                value={detailData.payment_telephone}
                disabled={true}
              />
            </Col>
            <Col>
              <Input
                title="Payment Email :"
                value={detailData.email}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input title="Transfer Date :" disabled={true} />
            </Col>
            <Col>
              <Input title="Transfer Bank :" disabled={true} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input title="Transfer Amount :" disabled={true} />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Total;

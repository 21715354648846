import React, { useState } from "react";
import { Button, Checkbox, Form, Grid, Input, theme, Typography } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/store/authSlice";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/eureka.png";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);

  const { token } = useToken();
  const screens = useBreakpoint();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (values) => {
    await dispatch(
      login({ username: values.useraname, password: values.password })
    );

    if (!authState.error) {
      navigate("/");
    }
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <img
            src={Logo}
            alt="Eureka"
            style={{ width: "28px", height: "28px", marginBottom: "4px" }}
          />

          <Title style={styles.title}>Login</Title>
          <Text style={styles.text}>
            Selamat Datang <b>Administrator</b> silahkan isi form login untuk
            melanjutkan!
          </Text>
        </div>
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          layout="vertical"
          requiredMark="optional"
          onFinish={handleLogin}
        >
          <Form.Item
            name="useraname"
            rules={[
              {
                required: true,
                message: "Username Tidak Boleh Kosong!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Username"
              value={formData.username}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password Tidak Boleh Kosong!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button block="true" type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
          {authState.error && (
            <p style={{ color: "red", marginTop: "10px" }}>
              Username atau Password salah, silahkan coba kembali.
            </p>
          )}
        </Form>
      </div>
    </section>
  );
}

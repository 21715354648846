import api from "../utils/api";

const categoryService = {
  fetchCategoryData: async (page) => {
    const start = page * 10;
    const length = 10;
    return await api.get(
      `/master/category_get?start=${start}&length=${length}`
    );
  },

  fetchCategoryId: async (categoryId) => {
    return await api.get(`/master/category_id_get/${categoryId}`);
  },

  updateCategory: async (categoryData) => {
    const formData = new FormData();
    formData.append("category_id", categoryData?.category_id);
    formData.append("parent_id", categoryData?.parent_id);
    formData.append("status", categoryData?.status);
    formData.append("name", categoryData?.name);
    formData.append("description", categoryData?.description);
    formData.append("meta_description", categoryData?.meta_description);
    formData.append("meta_keyword", categoryData?.meta_keyword);
    formData.append("seo", categoryData?.seo);
    formData.append("image", categoryData?.image);

    return await api.post("/master/category_update", formData);
  },

  createCategory: async (categoryData) => {
    const formData = new FormData();
    formData.append("parent_id", categoryData?.parent_id);
    formData.append("status", categoryData?.status);
    formData.append("name", categoryData?.name);
    formData.append("description", categoryData?.description);
    formData.append("meta_description", categoryData?.meta_description);
    formData.append("meta_keyword", categoryData?.meta_keyword);
    formData.append("seo", categoryData?.seo);
    formData.append("image", categoryData?.image);

    return await api.post("/master/category_create", formData);
  },

  deleteCategory: async (categoryId) => {
    const formData = new FormData();
    formData.append("category_id", categoryId);

    return await api.post("/master/category_delete", formData);
  },
};

export default categoryService;

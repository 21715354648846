import React from "react";
import Input from "../../part/Input";
import { Col, Container, Row, Button } from "react-bootstrap";
import { DatePicker } from "antd";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

const { RangePicker } = DatePicker;

const PromoTambahan = () => {
  const gridStyle = { minHeight: 120, marginTop: 10 };
  const columnsProduk = [
    {
      name: "id",
      type: "number",
      maxWidth: 40,
      header: "ID",
      defaultVisible: false,
    },
    { name: "produk", defaultFlex: 2, header: "Produk" },
    { name: "model", defaultFlex: 2, header: "Model" },
    { name: "gabungan", defaultFlex: 3, header: "Gabungan" },
    { name: "online", defaultFlex: 3, header: "Online" },
    { name: "weight", defaultFlex: 3, header: "Weight" },
    { name: "qty", defaultFlex: 3, header: "Qty" },
    { name: "unitPrice", defaultFlex: 3, header: "Unit Price" },
    { name: "total", defaultFlex: 3, header: "Total" },
    {
      name: "operasi",
      header: "Operasi",
      render: ({ data }) => (
        <Button
          variant="danger"
          size="sm"
          className="d-flex justify-content-center"
        >
          X
        </Button>
      ),
    },
  ];

  const dataSourceProduk = [
    {
      id: 1,
      produk: "T-Shirt",
      model: "XL",
      gabungan: "XL T-Shirt",
      online: "Online",
      weight: "1000",
      qty: "2",
      unitPrice: "1000",
    },
  ];
  return (
    <div className="card mb-3 p-3">
      <div className="d-flex justify-content-between mb-3 ">
        <div>
          <p className="text-primary fw-bold fs-5">Promo Tambahan :</p>
        </div>
        <div className="d-flex gap-2">
          <div>
            <Button variant="success" size="sm">
              Simpan Promo
            </Button>
          </div>
          <div>
            <Button variant="danger" size="sm">
              Clear Promo
            </Button>
          </div>
        </div>
      </div>
      <Container>
        <Row>
          <Col>
            <Input title="Keterangan" />
          </Col>
          <Col>
            <Input title="Diskon" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type="date" title="Tanggal Mulai" />
          </Col>
          <Col>
            <Input type="date" title="Tanggal Akhir" />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactDataGrid
              idProperty="id"
              style={gridStyle}
              columns={columnsProduk}
              pagination
              dataSource={dataSourceProduk}
              defaultLimit={10}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PromoTambahan;

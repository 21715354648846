import React, { useState } from "react";
import { Link } from "react-router-dom";

const Sidebar = ({
  isSidebarOpen,
  isSidebarHoverable,
  handleSidebarHover,
  handleSidebarLeave,
  handleSidebarExpand,
  handleSidebarClose,
}) => {
  const [activeSubmenu1, setActiveSubmenu1] = useState(null);
  const [activeSubmenu2, setActiveSubmenu2] = useState(null);

  const handleSubmenuClick = (submenuIndex) => {
    if (submenuIndex === 0) {
      setActiveSubmenu1(activeSubmenu1 === 0 ? null : 0);
    } else if (submenuIndex === 1) {
      setActiveSubmenu2(activeSubmenu2 === 1 ? null : 1);
    }
  };
  return (
    <>
      <nav
        className={`sidebar ${isSidebarOpen ? "close" : ""} ${
          isSidebarHoverable ? "hoverable" : ""
        }`}
        onMouseEnter={handleSidebarHover}
        onMouseLeave={handleSidebarLeave}
      >
        <div className="menu_content">
          <ul className="menu_items">
            <div className="menu_title menu_dahsboard"></div>
            <li className="item">
              <Link to="/" className="nav_link">
                <span className="navlink_icon">
                  <i className="bx bxs-dashboard"></i>
                </span>
                <span className="navlink">Dashboard</span>
              </Link>
            </li>
            <li className="item">
              <Link to="/pesanan" className="nav_link">
                <span className="navlink_icon">
                  <i className="bx bxs-cart"></i>
                </span>
                <span className="navlink">Pesanan EBH</span>
              </Link>
            </li>
          </ul>

          <ul className="menu_items">
            <div className="menu_title menu_editor"></div>
            <li className="item">
              <div
                href="#"
                className={`nav_link submenu_item ${
                  activeSubmenu1 === 0 ? "show_submenu" : ""
                }`}
                onClick={() => handleSubmenuClick(0)}
              >
                <span className="navlink_icon">
                  <i class="bx bxs-receipt"></i>
                </span>
                <span className="navlink">Katalog</span>
                <i className="bx bxs-chevron-right arrow-left"></i>
              </div>

              <ul className="menu_items submenu">
                <Link to="/produk" className="nav_link sublink">
                  Produk
                </Link>
                <Link to="/kategori" className="nav_link sublink">
                  Kategori
                </Link>
                <Link to="/merek" className="nav_link sublink">
                  Merek
                </Link>
                <Link to="/author" className="nav_link sublink">
                  Author
                </Link>
                <Link to="/user" className="nav_link sublink">
                  User
                </Link>
              </ul>
            </li>
            <li className="item">
              <Link to="/review" className="nav_link">
                <span className="navlink_icon">
                  <i class="bx bxs-star-half"></i>
                </span>
                <span className="navlink">Review</span>
              </Link>
            </li>
            <li className="item">
              <div
                href="#"
                className={`nav_link submenu_item ${
                  activeSubmenu2 === 1 ? "show_submenu" : ""
                }`}
                onClick={() => handleSubmenuClick(1)}
              >
                <span className="navlink_icon">
                  <i class="bx bxs-store-alt"></i>
                </span>
                <span className="navlink">Marketing</span>
                <i className="bx bx-chevron-right arrow-left"></i>
              </div>

              <ul className="menu_items submenu">
                <Link to="/marketing/banner" className="nav_link sublink">
                  Banner
                </Link>
                <Link to="/marketing/promo" className="nav_link sublink">
                  Promo
                </Link>
                <Link to="/marketing/kupon" className="nav_link sublink">
                  Kupon
                </Link>
              </ul>
            </li>
          </ul>

          <div className="bottom_content">
            <div
              className="bottom expand_sidebar"
              onClick={handleSidebarExpand}
            >
              <span> Expand</span>
              <i className="bx bx-log-in"></i>
            </div>
            <div
              className="bottom collapse_sidebar"
              onClick={handleSidebarClose}
            >
              <span> Collapse</span>
              <i className="bx bx-log-out"></i>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;

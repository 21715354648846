// services/userService.js
import api from "../utils/api";

const userService = {
  fetchUserData: async (page) => {
    const start = page * 10;
    const length = 10;
    return await api.get(`/master/user_get?start=${start}&length=${length}`);
  },

  fetchUserEditData: async (userId) => {
    return await api.get(`/master/user_id_get/${userId}`);
  },
};

export default userService;

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderData,
  fetchAllOrderData,
} from "../../redux/store/orderSlice";
import "@inovua/reactdatagrid-community/index.css";
import { Col, Row } from "react-bootstrap";
import { DatePicker, Pagination, Skeleton, Tag, Radio, Spin } from "antd"; // Import Spin from antd
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import ModalComponent from "../part/Modal";
import SingleButton from "../part/Button";
import ReactSelect from "../part/ReactSelect";
import SearchInput from "../part/SearchInput";
import TableData from "../part/Table";
import CustomButtonGroup from "../part/GroupButton";
import AksiPesanan from "./AksiPesanan";

const TablePesanan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading, totalRows, allData, editData } = useSelector(
    (state) => state.order
  );
  const [currentPage, setCurrentPage] = useState(1);
  const gridStyle = { minHeight: 500, marginTop: 10 };
  const [selectedStatus, setSelectedStatus] = useState("");
  const defaultYear = 2023;
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [exporting, setExporting] = useState(false); // State for export loading

  const handleShowAction = (orderId) => {
    setSelectedOrderId(orderId);
    setShowModal(true);
    console.log(orderId);
  };

  const handleDetail = (orderId) => {
    navigate(`/pesanan/detail/${orderId}`);
  };

  const exportToExcel = async () => {
    try {
      setExporting(true); // Set exporting state to true
      const response = await dispatch(fetchAllOrderData(selectedYear));
      const exportData = response.payload.data.map((item) => ({
        Invoice: item.invoice_no,
        OrderID: item.order_id,
        Customer: item.email,
        ShippingName: `${item.shipping_firstname} ${item.shipping_lastname}`,
        Status: item.order_status_name,
        Total: item.total,
        TanggalOrder: item.date_added,
      }));
      const ws = XLSX.utils.json_to_sheet(exportData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      const currentDate = moment().format("YYYYMMDD");
      const randomDigits = Math.floor(Math.random() * 900000) + 100000;
      const fileName = `order_data_${currentDate}_${randomDigits}.xlsx`;

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log("Selected Year:", selectedYear);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    } finally {
      setExporting(false); // Reset exporting state to false
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchOrderData({ page: page - 1, status: selectedStatus }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchOrderData({ status: selectedStatus }));
        const allDataResponse = await dispatch(fetchAllOrderData());
        const allData = allDataResponse.payload.data;
      } catch (error) {
        console.error("Error fetching data in TablePesanan:", error);
      }
    };

    fetchData();
  }, [dispatch, selectedStatus]);

  const statusTagMap = {
    Complete: "green",
    Canceled: "red",
    Expired: "gray",
    Processing: "blue",
    Pending: "orange",
    Shipped: "cyan",
    Refund: "volcano",
    Processed: "geekblue",
  };

  const columns = [
    {
      dataIndex: "id",
      title: "No",
      render: (text, record, index) => index + 1 + (currentPage - 1) * 10,
    },
    { dataIndex: "invoice_no", title: "Invoice", defaultFlex: 2 },
    { dataIndex: "order_id", title: "Order ID", defaultFlex: 2 },
    { dataIndex: "email", title: "Customer", defaultFlex: 2 },
    {
      dataIndex: "shipping_name",
      title: "Shipping Name",
      render: (text, record) =>
        `${record.shipping_firstname} ${record.shipping_lastname}`,
    },
    {
      dataIndex: "order_status_name",
      title: "Status",
      render: (text, record) => (
        <Tag color={statusTagMap[record.order_status_name]}>
          {record.order_status_name}
        </Tag>
      ),
    },
    { dataIndex: "total", title: "Total", defaultFlex: 2 },
    { dataIndex: "date_added", title: "Tanggal Order", defaultFlex: 2 },
    {
      dataIndex: "actions",
      title: "Aksi",
      render: (text, record) => (
        <CustomButtonGroup
          color={["primary", "success"]}
          text={["Detail", "Action"]}
          onClickHandler={[
            () => handleDetail(record.order_id),
            () => handleShowAction(record.order_id),
          ]}
        >
          Detail
        </CustomButtonGroup>
      ),
    },
  ];

  const status = [
    {
      value: "2",
      label: "Sudah Konfirmasi",
    },
    {
      value: "15",
      label: "Packing Proses",
    },
    {
      value: "3",
      label: "Shipping",
    },
    {
      value: "5",
      label: "Complete",
    },
    {
      value: "7",
      label: "Canceled",
    },
    {
      value: "11",
      label: "Refund",
    },
    {
      value: "14",
      label: "Expired",
    },
    {
      value: "0",
      label: "Belum Konfirmasi",
    },
  ];

  const handleClose = () => setShowModal(false);

  return (
    <>
      <div className="card w-full p-3 mb-3">
        <div>
          <h5 className="text-primary">List Pesanan</h5>
        </div>
        <Row className="mb-3">
          <Col>
            <SearchInput placeholder="Cari No Order..." size="large" />
          </Col>
          <Col>
            <ReactSelect
              placeholder="Pilih Status..."
              options={status}
              value={status.find((s) => s.value === selectedStatus)}
              onChange={(option) => setSelectedStatus(option.value)}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <div className="d-flex gap-2 flex-column">
            <DatePicker
              picker="year"
              defaultValue={moment(`${defaultYear}`, "YYYY")}
              onChange={(date, dateString) => {
                const year = dateString
                  ? new Date(dateString).getFullYear()
                  : null;
                setSelectedYear(year);
              }}
              style={{
                width: "100%",
              }}
            />
            <SingleButton color="success" size="small" onClick={exportToExcel}>
              {exporting ? ( // Conditionally render text based on exporting state
                <Spin />
              ) : (
                "Export"
              )}
            </SingleButton>
          </div>
        </div>
        {loading ? (
          <Skeleton active />
        ) : (
          <Row className="mt-3">
            <Col>
              <TableData columns={columns} dataSource={data} />
            </Col>
          </Row>
        )}
        {showModal && (
          <ModalComponent
            size="xl"
            showModal={showModal}
            handleClose={() => setShowModal(false)}
            title="Aksi Pesanan"
          >
            <AksiPesanan
              orderData={editData}
              onSave={() => {
                handleClose();
                dispatch(fetchOrderData());
              }}
            />
          </ModalComponent>
        )}

        <Row style={{ marginTop: 16, textAlign: "center" }}>
          <Col>
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={totalRows}
              onChange={handlePageChange}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TablePesanan;

import React from "react";

const TextArea = (props) => {
  const { title, content, onChange, name } = props;

  return (
    <div className="mb-3">
      <label htmlFor="exampleFormControlTextarea1" className="form-label">
        {title}
      </label>
      <textarea
        className="form-control"
        id="exampleFormControlTextarea1"
        rows="3"
        value={content} // Add the value prop
        onChange={onChange} // Add the onChange handler
        name={name}
      />
    </div>
  );
};

export default TextArea;

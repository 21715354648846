// components/AksiAuthor.js
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Upload from "../part/Upload";
import Input from "../part/Input";
import TextArea from "../part/TextArea";
import authorService from "../../redux/services/authorService";

const AksiAuthor = ({ authorData, isAdding, onSave }) => {
  useEffect(() => {
    if (!authorData) {
      return "loading";
    }
  }, [authorData]);
  const [updatedData, setUpdatedData] = useState(authorData);

  const handleUpdateAuthor = async () => {
    try {
      if (isAdding) {
        await authorService.createAuthor(updatedData);
      } else {
        await authorService.updateAuthor(updatedData);
      }
      onSave();
    } catch (error) {
      console.error("Error updating author:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      <Container>
        <Row>
          <Col sm={5}>
            <Row>
              <Col>
                <Upload title="Cover" />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="card w-50 mb-3">{/* Preview Image */}</div>
                <p className="text-muted">
                  Max. 500 kb Type file (gif, jpg, png, bmp)
                </p>
              </Col>
            </Row>
          </Col>
          <Col sm={7}>
            <Row>
              <Col>
                <Input
                  title="Author"
                  value={updatedData.nama_author}
                  onChange={handleChange}
                  name="nama_author"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  title="Birthday Author"
                  type="date"
                  value={updatedData.birthday_author}
                  onChange={handleChange}
                  name="birthday_author"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  title="Description Author"
                  content={updatedData.description_author}
                  onChange={handleChange}
                  name="description_author"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Button variant="success" onClick={handleUpdateAuthor}>
        {isAdding ? "Tambah Author" : "Update Author"}
      </Button>
    </div>
  );
};

export default AksiAuthor;

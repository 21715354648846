import React, { useEffect, useState } from "react";
import categoryService from "../../../redux/services/categoryService";
import Upload from "../../part/Upload";
import Input from "../../part/Input";
import TextArea from "../../part/TextArea";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactSelect from "../../part/ReactSelect";

const BukuFlat = ({ categoryData, onSave }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [updatedData, setUpdatedData] = useState(categoryData);

  const handleUpdateCategory = async () => {
    try {
      await categoryService.updateCategory(updatedData);
      onSave();
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleSelectChange = (name, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setUpdatedData({
      ...categoryData,
      image: file,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const bookOption = [
    {
      value: "Enable",
      label: "Enable",
    },
    {
      value: "Disable",
      label: "Disable",
    },
  ];

  const selectStatus = [
    {
      value: "1",
      label: "Enable",
    },
    {
      value: "0",
      label: "Disable",
    },
  ];

  const defaultBookOption = bookOption.find(
    (option) => option.value === parseInt(categoryData.status, 10)
  );

  const defaultStatusOption = selectStatus.find(
    (option) => option.value === parseInt(categoryData.status, 10)
  );

  return (
    <div className="card p-3 mb-3">
      <div className="d-flex justify-content-between mb-3">
        <div>
          <p className="text-primary fw-bold fs-5">Buku Flat :</p>
        </div>
        <div>
          <Button variant="success" size="sm" onClick={handleUpdateCategory}>
            Update
          </Button>
        </div>
      </div>
      <Container>
        <Row>
          <Col sm={5}>
            <Row>
              <Col>
                <Upload title="Cover" onChange={handleImageChange} />
              </Col>
            </Row>
            <Row>
              {/* show image */}
              <Col>
                <div
                  className="card mb-3"
                  style={{ width: "150px", height: "150px" }}
                >
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                  <img
                    src={`https://eurekabookhouse.co.id/assets/front/images/icons/nerd/${updatedData.image}`}
                    alt={updatedData.name}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={7}>
            <Row>
              <Col>
                <Input
                  title="Nama Kategori"
                  value={updatedData.name}
                  onChange={handleChange}
                  name="name"
                />
              </Col>
              <Col>
                <Input
                  title="SEO"
                  value={updatedData.seo}
                  onChange={handleChange}
                  name="seo"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <ReactSelect
                  defaultValue={defaultStatusOption}
                  label="Status"
                  options={selectStatus}
                  onChange={(selectedOption) =>
                    handleSelectChange("status", selectedOption.value)
                  }
                  value={selectStatus.find(
                    (option) => option.value === updatedData.status
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  title="Deskripsi"
                  content={updatedData.description}
                  onChange={handleChange}
                  name="description"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  title="Keyword"
                  content={updatedData.meta_keyword}
                  onChange={handleChange}
                  name="meta_keyword"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BukuFlat;

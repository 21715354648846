// services/couponService.js
import api from "../utils/api";

const couponService = {
  fetchCouponData: async (page) => {
    const start = page * 10;
    const length = 10;
    return await api.get(
      `/marketing/coupon_get?start=${start}&length=${length}`
    );
  },

  fetchCouponId: async (couponId) => {
    return await api.get(`/marketing/coupon_id_get/${couponId}`);
  },

  updateCoupon: async (couponData) => {
    const formData = new FormData();
    formData.append("coupon_id", couponData.coupon_id);
    formData.append("name", couponData.name);
    formData.append("code", couponData.code);
    formData.append("jenis", couponData.jenis);
    formData.append("potongan", couponData.potongan);
    formData.append("type", couponData.type);
    formData.append("min_belanja", couponData.min_belanja);
    formData.append("discount", couponData.discount);
    formData.append("logged", couponData.logged);
    formData.append("shipping", couponData.shipping);
    formData.append("total", couponData.total);
    formData.append("date_start", couponData.date_start);
    formData.append("date_end", couponData.date_end);
    formData.append("status", couponData.status);

    return await api.post("/marketing/coupon_update", formData);
  },

  createCoupon: async (couponData) => {
    const formData = new FormData();
    formData.append("name", couponData.name);
    formData.append("code", couponData.code);
    formData.append("jenis", couponData.jenis);
    formData.append("potongan", couponData.potongan);
    formData.append("type", couponData.type);
    formData.append("min_belanja", couponData.min_belanja);
    formData.append("discount", couponData.discount);
    formData.append("logged", couponData.logged);
    formData.append("shipping", couponData.shipping);
    formData.append("total", couponData.total);
    formData.append("date_start", couponData.date_start);
    formData.append("date_end", couponData.date_end);
    formData.append("status", couponData.status);

    return await api.post("/marketing/coupon_create", formData);
  },

  deleteCoupon: async (couponId) => {
    const formData = new FormData();
    formData.append("coupon_id", couponId);

    return await api.post("/marketing/coupon_delete", formData);
  },
};

export default couponService;

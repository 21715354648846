import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPromoData, fetchPromoId } from "../../redux/store/promoSlice";
import "@inovua/reactdatagrid-community/index.css";
import { Button } from "react-bootstrap";
import ModalComponent from "../part/Modal";
import Input from "../part/Input";
import CustomButtonGroup from "../part/GroupButton";
import { Pagination, Modal } from "antd";
import promoService from "../../redux/services/promoService";
import AksiPromo from "./AksiPromo";
import TableData from "../part/Table";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;

const TablePromo = () => {
  const dispatch = useDispatch();
  const { data, loading, editData } = useSelector((state) => state.promo);

  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("tambah");
  const [selectPromoId, setSelectedPromoId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);

  const handleShowTambah = () => {
    setShowModal(true);
    setActionType("tambah");
  };

  const handleShowDetail = async (promoId) => {
    setActionType("detail");
    setSelectedPromoId(promoId);
    console.log("Selected Promo ID:", promoId);
    await dispatch(fetchPromoId(promoId));
    setShowModal(true);
  };

  const handleDelete = async (promoId) => {
    confirm({
      title: "Yakin Ingin Menonaktifkan Data Ini?",
      icon: <ExclamationCircleFilled />,
      content: "Data dapat aktif kembali setelah diaktifkan lewat edit",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          const response = await fetch(
            `https://ujianonline.eurekabookhouse.co.id/marketing/promo_delete`,
            {
              method: "POST",
              headers: {
                Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
              },
              body: createDeleteFormData(promoId),
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          console.log("Deleted data:", result);

          if (result.success) {
            console.log(result.message);
          } else {
            console.error(result.message);
          }
        } catch (error) {
          console.error("Error deleting data:", error);
        }
      },
    });
  };

  const createDeleteFormData = (promoId) => {
    const formData = new FormData();
    formData.append("id_author", promoId);

    return formData;
  };

  const handleClose = () => setShowModal(false);
  const gridStyle = { minHeight: 500, marginTop: 10 };

  const columns = [
    {
      dataIndex: "id",
      title: "No",
      render: (text, record, index) => index + 1 + (currentPage - 1) * 10,
    },
    { dataIndex: "image", title: "Gambar" },
    { dataIndex: "judul_promo", title: "Judul" },
    { dataIndex: "berlaku_di", title: "Platform" },
    { dataIndex: "berlaku_mulai", title: "Mulai" },
    { dataIndex: "berlaku_sampai", title: "Berakhir" },
    {
      dataIndex: "actions",
      title: "Aksi",
      render: (text, record) => (
        <div className="text-center">
          <CustomButtonGroup
            color={["primary", "danger"]}
            text={["Edit", "Disable"]}
            onClickHandler={[
              () => handleShowDetail(record.id_promo),
              () => handleDelete(record.id_promo),
            ]}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchPromoData(page - 1));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await promoService.fetchPromoData();
        dispatch(fetchPromoData());
        if (response.success) {
          setTotalRows(response.totalRow);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div className="card w-full p-3 mb-3">
      <h5 className="text-primary">List Promo</h5>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2">
          <Input placeholder="Cari ..." />
        </div>
        <div>
          <Button
            variant="success"
            size="sm"
            className="text-white"
            onClick={handleShowTambah}
          >
            Tambah
          </Button>
        </div>
      </div>
      <ModalComponent
        size="xl"
        showModal={showModal}
        handleClose={handleClose}
        title={
          actionType === "tambah" ? "Tambah Data Promo" : "Detail Data Promo"
        }
      >
        <AksiPromo
          promoData={actionType === "tambah" ? {} : editData}
          isAdding={actionType === "tambah"}
          onSave={() => {
            handleClose();
            dispatch(fetchPromoData());
          }}
        />
      </ModalComponent>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableData columns={columns} dataSource={data} />
      )}
      <div style={{ marginTop: 16, textAlign: "center" }}>
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          total={totalRows}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default TablePromo;

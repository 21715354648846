import React from "react";
import TableReview from "../../components/review/TableReview";

const Review = () => {
  return (
    <div>
      <TableReview />
    </div>
  );
};

export default Review;

import React, { useEffect, useState } from "react";
import SelectComponent from "../../part/Select";
import { Container, Row, Col } from "react-bootstrap";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

const Konten = () => {
  const [categoryOption, setCategoryOption] = useState([]);

  const fetchLinksOption = async () => {
    try {
      const response = await fetch(
        "https://ujianonline.eurekabookhouse.co.id/master/option_category_get",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setCategoryOption(result.data);
    } catch (error) {
      console.error("Error fetching supplier options:", error);
    }
  };

  useEffect(() => {
    fetchLinksOption();
  }, []);

  const gridStyle = { minHeight: 120, marginTop: 10 };

  const columns = [
    { name: "nama", defaultFlex: 2, header: "Nama" },
    { name: "aksi", defaultFlex: 2, header: "Aksi" },
  ];

  const dataSource = [
    {
      id: 1,
      cover: "https://picsum.phosadtos/200/300",
      kategori: "Kategori",
      produk: "Produk",
      seo: "SEO",
      status: "Status",
    },
  ];

  return (
    <div>
      <Container>
        <h5>Content</h5>
        <Row>
          <Col>
            <SelectComponent label="Category" option={categoryOption} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactDataGrid
              idProperty="id"
              style={gridStyle}
              columns={columns}
              pagination
              dataSource={dataSource}
              defaultLimit={10}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Konten;

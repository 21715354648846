import api from "../utils/api";

const bannerService = {
  fetchBannerData: async () => {
    return await api.get("/marketing/banner_get");
  },

  fetchBannerId: async (bannerId) => {
    return await api.get(`/marketing/banner_id_get?id=${bannerId}`);
  },

  updateBanner: async (bannerData) => {
    const formData = new FormData();
    formData.append("id", bannerData.id);
    formData.append("slider", bannerData.slider);
    formData.append("link", bannerData.link);
    formData.append("status", bannerData.status);

    return await api.post("/marketing/banner_update", formData);
  },

  createBanner: async (bannerData) => {
    const formData = new FormData();
    formData.append("slider", bannerData.slider);
    formData.append("link", bannerData.link);

    return await api.post("/marketing/banner_create", formData);
  },
};

export default bannerService;

// services/promoService.js
import api from "../utils/api";

const promoService = {
  fetchPromoData: async (page) => {
    const start = page * 10;
    const length = 10;
    return await api.get(
      `/marketing/promo_get?start=${start}&length=${length}`
    );
  },

  fetchPromoId: async (promoId) => {
    return await api.get(`/marketing/promo_id_get/${promoId}`);
  },

  updatePromo: async (promoData) => {
    const formData = new FormData();
    formData.append("id_promo", promoData.id_promo);
    formData.append("id_promo_kategori", promoData.id_promo_kategori);
    formData.append("judul_promo", promoData.judul_promo);
    formData.append("slug", promoData.slug);
    formData.append("subjudul_promo", promoData.subjudul_promo);
    formData.append("minimum_pembelian", promoData.minimum_pembelian);
    formData.append("berlaku_di", promoData.berlaku_di);
    formData.append("berlaku_mulai", promoData.berlaku_mulai);
    formData.append("berlaku_sampai", promoData.berlaku_sampai);
    formData.append("syarat_ketentuan", promoData.syarat_ketentuan);
    formData.append("klaim_bersyarat", promoData.klaim_bersyarat);
    formData.append("image", promoData.image);

    return await api.post("/marketing/promo_update", formData);
  },

  createPromo: async (promoData) => {
    const formData = new FormData();
    formData.append("id_promo_kategori", promoData.id_promo_kategori);
    formData.append("judul_promo", promoData.judul_promo);
    formData.append("slug", promoData.slug);
    formData.append("subjudul_promo", promoData.subjudul_promo);
    formData.append("minimum_pembelian", promoData.minimum_pembelian);
    formData.append("berlaku_di", promoData.berlaku_di);
    formData.append("berlaku_mulai", promoData.berlaku_mulai);
    formData.append("berlaku_sampai", promoData.berlaku_sampai);
    formData.append("syarat_ketentuan", promoData.syarat_ketentuan);
    formData.append("klaim_bersyarat", promoData.klaim_bersyarat);
    formData.append("image", promoData.image);

    return await api.post("/marketing/promo_create", formData);
  },

  deletePromo: async (promoId) => {
    const formData = new FormData();
    formData.append("id_promo", promoId);

    return await api.post("/marketing/promo_delete", formData);
  },
};

export default promoService;

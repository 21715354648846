import React, { useState, useEffect } from "react";
import CardComponent from "../../components/dashboard/Card";
import { Row, Col, Container } from "react-bootstrap";

import { GiShoppingCart, GiTakeMyMoney } from "react-icons/gi";
import { FaDiagramSuccessor } from "react-icons/fa6";
import { VscVmActive } from "react-icons/vsc";
import {
  MdOutlinePendingActions,
  MdAssignmentReturned,
  MdCancel,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";
import { GrPowerCycle } from "react-icons/gr";
import { FaShippingFast } from "react-icons/fa";
import { TbShoppingCartCancel } from "react-icons/tb";
import RekapTransaksi from "../../components/dashboard/RekapTransaksi";
import TableMediaOrder from "../../components/dashboard/TableMediaOrder";
import AreaChart from "../../components/dashboard/Chart";
import { Skeleton } from "antd";

const Dashboard = () => {
  const [orderData, setOrderData] = useState(null);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://ujianonline.eurekabookhouse.co.id/report/dashboard_get`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer qtUPRXyF8NsA3U37wcPNMw==`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setOrderData(data.data.order);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);
  const bulanOption = [
    {
      value: "January",
      label: "January",
    },
    {
      value: "February",
      label: "February",
    },
    {
      value: "March",
      label: "March",
    },
    {
      value: "April",
      label: "April",
    },
    {
      value: "May",
      label: "May",
    },
  ];

  const tahunOption = [
    {
      value: "2023",
      label: "2023",
    },
    {
      value: "2024",
      label: "2024",
    },
    {
      value: "2025",
      label: "2025",
    },
    {
      value: "2026",
      label: "2026",
    },
  ];

  const selectedOrderStatusIds = ["5", "3", "0", "15", "6", "7"];

  const chunkArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  return (
    <>
      <Container className="d-flex flex-column gap-4">
        <Container>
          {orderData && (
            <div className="d-flex flex-wrap gap-3">
              {chunkArray(
                orderData.filter((item) =>
                  selectedOrderStatusIds.includes(item.order_status_id)
                ),
                4
              ).map((row, rowIndex) => (
                <div key={rowIndex} className="d-flex w-100 gap-3">
                  {row.map((item, index) => (
                    <div key={index} className="flex-grow-1">
                      <CardComponent
                        index={rowIndex * 4 + index} // Hitung indeks secara keseluruhan
                        value={item.jumlah}
                        title={item.name}
                        icon={getIconForOrderStatus(item.order_status_id)}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </Container>

        <Container className="d-flex align-items-center justify-content-center flex-column gap-4">
          <Row className="card w-100 p-3">
            <Col>
              <div className="mb-3 text-primary fs-5 fw-bold">
                Grafik Penjualan :
              </div>
              <AreaChart />
            </Col>
          </Row>

          <Row className="card w-100 p-3">
            <Col>{loading ? <Skeleton active /> : <TableMediaOrder />}</Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

const getIconForOrderStatus = (orderStatusId) => {
  switch (orderStatusId) {
    case "0":
      return GiShoppingCart;
    case "1":
      return MdOutlinePendingActions;
    case "2":
      return GrPowerCycle;
    case "3":
      return FaShippingFast;
    case "4":
      return FaDiagramSuccessor;
    case "5":
      return VscVmActive;
    case "6":
      return MdAssignmentReturned;
    case "7":
      return TbShoppingCartCancel;
    case "8":
      return MdCancel;
    case "11":
      return GiTakeMyMoney;
    case "14":
      return MdOutlineProductionQuantityLimits;
    case "15":
      return GrPowerCycle;

    default:
      return GiShoppingCart;
  }
};

export default Dashboard;

// lin
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

// import
import Input from "../../part/Input";
import SingleButton from "../../part/Button";

const TrackPesanan = () => {
  const [trackingResult, setTrackingResult] = useState(null);

  const handleTrack = async () => {
    try {
      const resi = "99LP1710262343750";
      const response = await fetch(
        "https://apirace.eurekalogistics.co.id/price/cek-kiriman",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ resi }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTrackingResult(data); 
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <Container className="card mb-3 p-3">
      <Container className="d-flex justify-content-between mb-3">
        <p className="text-primary fw-bold fs-5">Lacak Pesanan :</p>
      </Container>
      <Container>
        <Row>
          <Col>
            <Input placeholder="Masukan No Resi" />
          </Col>
        </Row>
        <Row>
          <Col>
            <SingleButton color="success" size="small" onClick={handleTrack}>
              Check Track
            </SingleButton>
          </Col>
        </Row>
        {trackingResult && (
          <Row>
            <Col>
              <div>
                <p>Origin: {trackingResult.data.rajaongkir.result.summary.origin}</p>
                <p>Destination: {trackingResult.data.rajaongkir.result.summary.destination}</p>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Container>
  );
};

export default TrackPesanan;

import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Input from "../../part/Input";
import SingleButton from "../../part/Button";

const DataPengirim = ({ detailData }) => {
  const customerName = `${detailData.shipping_firstname} ${detailData.shipping_lastname}`;
  return (
    <div className="card mb-3 p-3">
      <Container>
        <div className="d-flex justify-content-between mb-3">
          <p className="text-primary fw-bold fs-5">Detail Data Pengirim :</p>
        </div>
        <Row>
          <Col>
            <Input title="Customer :" value={customerName} disabled={true} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Fisrt Name :"
              value={detailData.shipping_firstname}
              disabled={true}
            />
          </Col>
          <Col>
            <Input
              title="Last Name :"
              value={detailData.shipping_lastname}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Email :"
              type="email"
              value={detailData.email}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Phone :"
              value={detailData.telephone}
              disabled={true}
            />
          </Col>
          <Col>
            <Input title="Fax :" value={detailData.fax} disabled={true} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DataPengirim;

import React from "react";
import TableKupon from "../../../components/kupon/TableKupon";

const Kupon = () => {
  return (
    <div>
      <TableKupon />
    </div>
  );
};

export default Kupon;

import React from "react";
import TableMerek from "../../../components/merek/TableMerek";

const Merek = () => {
  return (
    <div>
      <TableMerek />
    </div>
  );
};

export default Merek;

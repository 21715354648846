import api from "../utils/api";

const orderService = {
  fetchOrderData: async (page = 0, status) => {
    const start = page * 10;
    const length = 10;
    const statusParam = status ? `&status=${status}` : "";
    return await api.get(
      `/order/order_get?start=${start}&length=${length}${statusParam}`
    );
  },

  fetchAllOrderData: async (year) => {
    const response = await api.get(
      `/order/order_get?start=0&length=0&year=${year}`
    );
    return response;
  },

  fetchOrderId: async (orderId) => {
    return await api.get(`/order/order_id_get/${orderId}`);
  },
};

export default orderService;

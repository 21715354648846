import { AudioOutlined } from "@ant-design/icons";
import React from "react";
import { Input } from "antd";

const { Search } = Input;

const SearchInput = (props) => {
  const { placeholder, size = "default" } = props;
  return <Search placeholder={placeholder} size={size} block />;
};

export default SearchInput;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAuthorData,
  fetchAuthorId,
  deleteAuthorData,
} from "../../redux/store/authorSlice";
import { Col, Row, Button, Container } from "react-bootstrap";
import ModalComponent from "../part/Modal";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Input from "../part/Input";
import AksiAuthor from "./AksiAuthor";
import CustomButtonGroup from "../part/GroupButton";
import { Pagination, Skeleton, Modal } from "antd";
import TableData from "../part/Table";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;

const TableAuthor = () => {
  const dispatch = useDispatch();
  const { data, loading, editData, totalRows } = useSelector(
    (state) => state.author
  );

  const gridStyle = { minHeight: 500, marginTop: 10 };
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("tambah");
  const [selectedAuthorId, setSelectedAuthorId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleShowTambah = () => {
    setShowModal(true);
    setActionType("tambah");
    setSelectedAuthorId(null);
  };

  const handleShowDetail = async (authorId) => {
    setActionType("edit");
    setSelectedAuthorId(authorId);
    console.log("Selected authors ID:", authorId);
    await dispatch(fetchAuthorId(authorId));
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setActionType("tambah");
  };

  const handleDelete = async (authorId) => {
    confirm({
      title: "Yakin Ingin Menonaktifkan Data Ini?",
      icon: <ExclamationCircleFilled />,
      content: "Data dapat aktif kembali setelah diaktifkan lewat edit",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          await dispatch(deleteAuthorData(authorId));
          dispatch(fetchAuthorData(currentPage - 1));
        } catch (error) {
          console.error("Error deleting product:", error);
        }
      },
    });
  };

  const columns = [
    {
      dataIndex: "id",
      title: "No",
      render: (text, record, index) => index + 1 + (currentPage - 1) * 10,
    },
    { dataIndex: "nama_author", title: "Nama Author" },
    {
      dataIndex: "actions",
      title: "Aksi",
      render: (text, record) => (
        <div className="text-center">
          <CustomButtonGroup
            color={["primary", "danger"]}
            text={["Edit", "Disable"]}
            onClickHandler={[
              () => handleShowDetail(record.id_author),
              () => handleDelete(record.id_author),
            ]}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchAuthorData(page - 1));
  };

  useEffect(() => {
    dispatch(fetchAuthorData());
  }, [dispatch]);

  return (
    <div className="card w-full p-3 mb-3">
      <h5 className="text-primary">List Author</h5>
      <ModalComponent
        size="xl"
        showModal={showModal}
        handleClose={handleClose}
        title={
          actionType === "tambah" ? "Tambah Data Author" : "Detail Data Author"
        }
      >
        <AksiAuthor
          authorData={actionType === "tambah" ? {} : editData}
          isAdding={actionType === "tambah"}
          onSave={() => {
            handleClose();
            dispatch(fetchAuthorData());
          }}
        />
      </ModalComponent>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2">
          <Input placeholder="Cari ..." />
        </div>
        <div>
          <Button
            variant="success"
            size="sm"
            className="text-white"
            onClick={handleShowTambah}
          >
            Tambah
          </Button>
        </div>
      </div>
      <Row>
        <Col>
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <TableData columns={columns} dataSource={data} />
            </>
          )}
          <div style={{ marginTop: 16, textAlign: "center" }}>
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={totalRows}
              onChange={handlePageChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TableAuthor;

// ModalComponent.js
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ModalComponent = ({
  showModal,
  handleClose,
  title,
  children,
  secondaryButton,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {secondaryButton}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;

// services/merekService.js
import api from "../utils/api";

const merekService = {
  fetchMerekData: async (page) => {
    const start = page * 10;
    const length = 10;
    return await api.get(
      `/master/manufacturer_get?start=${start}&length=${length}`
    );
  },

  fetchMerekId: async (merekId) => {
    return await api.get(`/master/manufacturer_id_get/${merekId}`);
  },

  updateMerek: async (merekData) => {
    const formData = new FormData();
    formData.append("manufacturer_id", merekData.manufacturer_id);
    formData.append("name", merekData.name);
    formData.append("description", merekData.description);
    formData.append("address", merekData.address);
    formData.append("owner", merekData.owner);
    formData.append("phone", merekData.phone);
    formData.append("email", merekData.email);
    formData.append("img_ktp", merekData.img_ktp);
    formData.append("bank_name", merekData.bank_name);
    formData.append("bank_number", merekData.bank_number);
    formData.append("official", merekData.official);
    formData.append("reseller", merekData.reseller);
    formData.append("status", merekData.status);
    formData.append("mall_id", merekData.mall_id);
    formData.append("image", merekData.image);

    return await api.post("/master/manufacturer_update", formData);
  },

  createMerek: async (merekData) => {
    const formData = new FormData();
    formData.append("name", merekData.name);
    formData.append("description", merekData.description);
    formData.append("address", merekData.address);
    formData.append("owner", merekData.owner);
    formData.append("phone", merekData.phone);
    formData.append("email", merekData.email);
    formData.append("img_ktp", merekData.img_ktp);
    formData.append("bank_name", merekData.bank_name);
    formData.append("bank_number", merekData.bank_number);
    formData.append("official", merekData.official);
    formData.append("reseller", merekData.reseller);
    formData.append("status", merekData.status);
    formData.append("mall_id", merekData.mall_id);
    formData.append("image", merekData.image);

    return await api.post("/master/manufacturer_create", formData);
  },

  deleteMerek: async (merekId) => {
    const formData = new FormData();
    formData.append("manufacturer_id", merekId);

    return await api.post("/master/manufacturer_delete", formData);
  },
};

export default merekService;

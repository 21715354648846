import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/eureka.png";
import { Button, Dropdown } from "antd";

const items = [
  {
    key: "1",
    label: (
      <div className="d-flex align-items-center">
        <div>
          <i className="bx bx-log-out"></i>
        </div>
        <div>Logout</div>
      </div>
    ),
  },
];

const Header = ({ handleSidebarOpen, isDarkMode, handleDarkLightToggle }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    clearTokenFromLocalStorage();
    navigate("/login");
  };

  const clearTokenFromLocalStorage = () => {
    localStorage.removeItem("token");
  };

  return (
    <>
      <nav className="navbar">
        <div className="logo_item">
          <i className="bx bx-menu" onClick={handleSidebarOpen}></i>
          <img src={Logo} alt="" />
          Eureka Book House!
        </div>

        <div className="navbar_content">
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomRight"
          >
            <Button onClick={handleLogout}>
              <i className="bx bxs-user-pin bx-sm"></i>
            </Button>
          </Dropdown>
        </div>
      </nav>
    </>
  );
};

export default Header;

import React from "react";
import { Form } from "react-bootstrap";

const Input = (props) => {
  const {
    title,
    type = "text",
    placeholder = "",
    value,
    disabled = false,
    onChange,
    name,
  } = props;

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        {title && <Form.Label>{title}</Form.Label>}
        <Form.Control
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          name={name}
        />
      </Form.Group>
    </Form>
  );
};

export default Input;

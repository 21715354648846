import React from "react";
import "./Card.css";

// ... (kode lainnya)

const CardComponent = (props) => {
  const { title, value, icon: Icon } = props;

  // Array warna yang akan digunakan pada setiap kartu
  const cardColors = [
    // "#006d75",
    // "#fadb14",
    // "#237804",
    // "#d46b08",
    // "#cf1322",
    "##1677ff",
  ];

  // Mendapatkan indeks warna berdasarkan urutan kartu
  const colorIndex = props.index % cardColors.length;

  // Menentukan warna berdasarkan indeks
  const cardStyle = {
    backgroundColor: cardColors[colorIndex],
  };

  return (
    <>
      <div className="box-container">
        <div className="box box1" style={cardStyle}>
          <div className="d-flex">
            <div className="text">
              <h2 className="topic-heading">{value}</h2>
              <h2 className="topic">{title}</h2>
            </div>
            {Icon && <Icon className="icon" />}
          </div>
        </div>
      </div>
    </>
  );
};
export default CardComponent;

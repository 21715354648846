import React from "react";
import BannerSlider from "../../../components/banner/BannerSlider";

const Banner = () => {
  return (
    <div>
      <BannerSlider />
    </div>
  );
};

export default Banner;

// utils/api.js
const BASE_URL = "https://ujianonline.eurekabookhouse.co.id";
const token = localStorage.getItem("token");

const api = {
  get: async (endpoint) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error in GET request:", error.message);
      throw error;
    }
  },

  post: async (endpoint, formData) => {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  },

  postLogin: async (endpoint, formData) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();

      if (responseData.token) {
        localStorage.setItem("token", responseData.token);
      }

      return responseData;
    } catch (error) {
      console.error("Error during login:", error.message);
      throw error;
    }
  },
};

export default api;

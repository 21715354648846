// store/couponSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import couponService from "../services/couponService";

export const fetchCouponData = createAsyncThunk(
  "coupon/fetchCouponData",
  async (page = 0) => {
    const response = await couponService.fetchCouponData(page);
    return response;
  }
);

export const fetchCouponId = createAsyncThunk(
  "coupon/fetchCouponId",
  async (couponId) => {
    const response = await couponService.fetchCouponId(couponId);
    return response.data;
  }
);

export const updateCouponId = createAsyncThunk(
  "coupon/updateCouponId",
  async (couponData) => {
    const response = await couponService.updateCoupon(couponData);
    return response.data;
  }
);

export const createCouponData = createAsyncThunk(
  "coupon/createCouponData",
  async (couponData) => {
    const response = await couponService.createCoupon(couponData);
    return response.data;
  }
);

export const deleteCouponData = createAsyncThunk(
  "coupon/deleteCouponData",
  async (couponId) => {
    const response = await couponService.deleteCoupon(couponId);
    return response.data;
  }
);

const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    data: [],
    editData: {},
    loading: false,
    editLoading: false,
    totalRows: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCouponData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCouponData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalRows = action.payload.totalRow || action.payload.total;
        console.log("Total Rows:", state.totalRows);
        state.loading = false;
      })
      .addCase(fetchCouponId.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchCouponId.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      })
      .addCase(updateCouponId.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(createCouponData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(deleteCouponData.fulfilled, (state, action) => {
        console.log(action.payload);
      });
  },
});

export default couponSlice.reducer;

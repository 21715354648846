import React from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import Input from "../part/Input";
import SelectComponent from "../part/Select";

const EditUser = ({ userData }) => {
  const { username, firstname, lastname, email } = userData;
  const bookOption = [
    {
      value: "Sudah Konfirmasi",
      label: "Sudah Konfirmasi",
    },
    {
      value: "Packing Proses",
      label: "Packing Proses",
    },
    {
      value: "Shipping",
      label: "Shipping",
    },
    {
      value: "Complete",
      label: "Complete",
    },
    {
      value: "Canceled",
      label: "Canceled",
    },
  ];
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Input title="First Name" value={firstname} />
          </Col>
          <Col>
            <Input title="Last Name" value={lastname} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input title="Username" value={username} />
          </Col>
          <Col>
            <Input title="Email" value={email} />
          </Col>
          <Col>
            <SelectComponent label="Status" option={bookOption} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditUser;

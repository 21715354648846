// DetailProduk.jsx
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductId } from "../../redux/store/productSlice";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import General from "./tab-produk/General";
import Gambar from "./tab-produk/Gambar";
import Links from "./tab-produk/Links";
import PromoTambahan from "./tab-produk/PromoTambahan";
import FlashSale from "./tab-produk/FlashSale";
import Category from "./tab-produk/Category";
import { Skeleton } from "antd";
import SingleButton from "../part/Button";

const DetailProduk = ({ onSave }) => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [productData, setProductData] = useState({});
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate("/produk");
  };

  useEffect(() => {
    const fetchProdukDetail = async () => {
      try {
        const response = await dispatch(fetchProductId(productId));
        setProductData(response.payload);
      } catch (error) {
        console.error("Error fetching detail data:", error);
      }
    };

    fetchProdukDetail();
  }, [dispatch, productId]);

  return (
    <div>
      <Container>
        <div className="d-flex justify-content-end mb-3">
          <div>
            <SingleButton color="primary" onClick={handleBack}>
              Kembali
            </SingleButton>
          </div>
        </div>
        <Row>
          <Col>
            {productData ? (
              <General productData={productData} />
            ) : (
              <Skeleton active />
            )}
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Gambar productData={productData} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Links productData={productData} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Category productData={productData} />
          </Col>
        </Row>
        <Row>
          <Col>
            <PromoTambahan productData={productData} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FlashSale productData={productData} />
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default DetailProduk;

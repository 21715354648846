// store/userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../services/userService";

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (page = 0) => {
    const response = await userService.fetchUserData(page);
    return response;
  }
);

export const fetchUserEditData = createAsyncThunk(
  "user/fetchUserEditData",
  async (userId) => {
    const response = await userService.fetchUserEditData(userId);
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: [],
    editData: {},
    loading: false,
    editLoading: false,
    totalRows: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalRows = action.payload.totalRow || action.payload.total;
        console.log("Total Rows:", state.totalRows);
        state.loading = false;
      })
      .addCase(fetchUserEditData.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchUserEditData.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      });
    //   .addCase(addUser.pending, (state) => {
    //     // Handle pending state (optional)
    //     // You can update loading indicators or perform any other actions
    //     console.log("Loading...");
    //   })
    //   .addCase(addUser.fulfilled, (state, action) => {
    //     // Handle fulfilled state
    //     // This is where you can update the state based on the result
    //     console.log("User added successfully:", action.payload);
    //   });
  },
});

export default userSlice.reducer;

// store/promoSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import promoService from "../services/promoService";

export const fetchPromoData = createAsyncThunk(
  "promo/fetchPromoData",
  async () => {
    const response = await promoService.fetchPromoData();
    return response.data;
  }
);

export const fetchPromoId = createAsyncThunk(
  "promo/fetchPromoId",
  async (promoId) => {
    const response = await promoService.fetchPromoId(promoId);
    return response.data;
  }
);

export const updatePromoId = createAsyncThunk(
  "promo/updatePromoId",
  async (promoData) => {
    const response = await promoService.updatePromo(promoData);
    return response.data;
  }
);

export const createPromoData = createAsyncThunk(
  "promo/createPromoData",
  async (promoData) => {
    const response = await promoService.createPromo(promoData);
    return response.data;
  }
);

export const deletePromoData = createAsyncThunk(
  "promo/deletePromoData",
  async (promoId) => {
    const response = await promoService.deletePromo(promoId);
    return response.data;
  }
);

const promoSlice = createSlice({
  name: "promo",
  initialState: {
    data: [],
    editData: {},
    loading: false,
    editLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromoData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromoData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchPromoId.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchPromoId.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      })
      .addCase(updatePromoId.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(createPromoData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(deletePromoData.fulfilled, (state, action) => {
        console.log(action.payload);
      });
  },
});

export default promoSlice.reducer;

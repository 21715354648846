import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCouponData,
  fetchCouponId,
  deleteCouponData,
} from "../../redux/store/couponSlice";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { Button } from "react-bootstrap";
import ModalComponent from "../part/Modal";
import Input from "../part/Input";
import DetailKupon from "./DetailKupon";
import TambahKupon from "./TambahKupon";
import CustomButtonGroup from "../part/GroupButton";
import { Pagination, Modal, Skeleton, Tag } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import TableData from "../part/Table";

const { confirm } = Modal;

const TableKupon = () => {
  const dispatch = useDispatch();
  const { data, loading, editData, totalRows } = useSelector(
    (state) => state.coupon
  );

  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("tambah");
  const [selectedCouponId, setSelectedCouponId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleShowTambah = () => {
    setShowModal(true);
    setActionType("tambah");
  };

  const handleShowDetail = async (couponId) => {
    setSelectedCouponId(couponId);
    await dispatch(fetchCouponId(couponId));
    setShowModal(true);
    setActionType("detail");
    console.log("Selected Kupon ID:", couponId);
  };

  const handleDelete = async (couponId) => {
    confirm({
      title: "Yakin Ingin Menonaktifkan Data Ini?",
      icon: <ExclamationCircleFilled />,
      content: "Data dapat aktif kembali setelah diaktifkan lewat edit",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          await dispatch(deleteCouponData(couponId));
          dispatch(fetchCouponData(currentPage - 1));
        } catch (error) {
          console.error("Error deleting product:", error);
        }
      },
    });
  };

  const handleClose = () => setShowModal(false);
  const gridStyle = { minHeight: 500, marginTop: 10 };

  const columns = [
    {
      dataIndex: "id",
      title: "No",
      render: (text, record, index) => index + 1 + (currentPage - 1) * 10,
    },
    { dataIndex: "name", title: "Nama Kupon" },
    { dataIndex: "code", title: "Kode" },
    { dataIndex: "discount", title: "Diskon" },
    { dataIndex: "date_start", title: "Tanggal Mulai" },
    { dataIndex: "date_end", title: "Tanggal Akhir" },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) =>
        record.status === "0" ? (
          <Tag color="red">Disabled</Tag>
        ) : (
          <Tag color="green">Enabled</Tag>
        ),
    },
    {
      dataIndex: "actions",
      title: "Aksi",
      render: (text, record) => (
        <div className="text-center">
          <CustomButtonGroup
            color={["primary", "danger"]}
            text={["Edit", "Disable"]}
            onClickHandler={[
              () => handleShowDetail(record.coupon_id),
              () => handleDelete(record.coupon_id),
            ]}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchCouponData(page - 1));
  };

  useEffect(() => {
    dispatch(fetchCouponData());
  }, [dispatch]);

  return (
    <div className="card w-full p-3 mb-3">
      <h5 className="text-primary">List Kupon</h5>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2">
          <Input placeholder="Cari ..." />
        </div>
        <div>
          <Button
            variant="success"
            size="sm"
            className="text-white"
            onClick={handleShowTambah}
          >
            Tambah
          </Button>
        </div>
      </div>
      <ModalComponent
        size="xl"
        showModal={showModal}
        handleClose={handleClose}
        title={
          actionType === "tambah" ? "Tambah Data Kupon" : "Detail Data Kupon"
        }
      >
        {actionType === "tambah" ? (
          <TambahKupon
            onSave={() => {
              handleClose();
              dispatch(fetchCouponData());
            }}
          />
        ) : (
          <DetailKupon
            couponData={editData}
            onSave={() => {
              handleClose();
              dispatch(fetchCouponData());
            }}
          />
        )}
      </ModalComponent>
      {loading ? (
        <Skeleton active />
      ) : (
        <TableData columns={columns} dataSource={data} />
      )}
      <div style={{ marginTop: 16, textAlign: "center" }}>
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          total={totalRows}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default TableKupon;

// UpdateBanner.jsx
import React, { useState } from "react";
import bannerService from "../../../redux/services/bannerService";
import { Container, Row, Col, Button } from "react-bootstrap";
import Upload from "../../part/Upload";
import ReactSelect from "../../part/ReactSelect";
import Input from "../../part/Input";
import SingleButton from "../../part/Button";

const UpdateBanner = ({ bannerData, onSave }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [updatedData, setUpdatedData] = useState(bannerData);

  const handleUpdateBanner = async () => {
    try {
      await bannerService.updateBanner(updatedData);
      onSave();
    } catch (error) {
      console.error("Error updating banner:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setUpdatedData((prevData) => ({
      ...prevData,
      slider: file,
    }));
  };

  const handleSelectChange = (name, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const selectStatus = [
    {
      value: "1",
      label: "Enable",
    },
    {
      value: "0",
      label: "Disable",
    },
  ];

  const defaultStatusOption = selectStatus.find(
    (option) => option.value === parseInt(bannerData.status, 10)
  );

  return (
    <div>
      <Container>
        <h3 className="text-center mb-5 fst-italic">Update Popup Banner</h3>
        <Row>
          <Col>
            <Row>
              <Col>
                <Upload title="Tautan" onChange={handleImageChange} />
                <div
                  className="card mb-3"
                  style={{ width: "480px", height: "170px" }}
                >
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                  <img
                    src={`https://eurekabookhouse.co.id/assets/uplod/slider/${
                      updatedData.slider || bannerData.slider
                    }`}
                    alt={updatedData.slider || bannerData.slider}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <p className="text-muted">
                  Isikan "#" jika tidak ingin menggunakan tautan pada banner
                  popup
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  title="Tautan"
                  name="link"
                  value={updatedData.link || bannerData.link}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactSelect
                  defaultValue={defaultStatusOption}
                  label="Status"
                  options={selectStatus}
                  onChange={(selectedOption) =>
                    handleSelectChange("status", selectedOption.value)
                  }
                  value={selectStatus.find(
                    (option) =>
                      option.value === (updatedData.status || bannerData.status)
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <p className="text-muted">Catatan:</p>
                <p className="text-muted">Ukuran banner popup:</p>
                <p className="text-muted">Tinggi: 560px</p>
                <p className="text-muted">Lebar: 480px</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <SingleButton color="success" onClick={handleUpdateBanner} block>
              Update
            </SingleButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UpdateBanner;

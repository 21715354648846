import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Upload from "../../../part/Upload";
import Input from "../../../part/Input";
import bannerService from "../../../../redux/services/bannerService";

const TambahBanner = ({ onSave }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [createdData, setCreatedData] = useState({
    link: "", // Initialize link as an empty string
    slider: null, // Initialize slider as null
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setCreatedData((prevData) => ({
      ...prevData,
      slider: file,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpload = async () => {
    try {
      await bannerService.createBanner(createdData);
      setSelectedImage(null);
      setCreatedData({
        link: "",
        slider: null,
      });
      onSave();
    } catch (error) {
      console.error("Error uploading banner:", error);
    }
  };

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Row>
              <Col>
                <Upload title="Photo" onChange={handleImageChange} />
              </Col>
            </Row>
            <Row>
              {/* show image */}
              <Col>
                <div
                  className="card mb-3"
                  style={{ width: "300px", height: "150px" }}
                >
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Input
                title="Link"
                value={createdData.link}
                onChange={handleChange}
                name="link"
              />
            </Row>
            <Row>
              <Col>
                <Button variant="primary" onClick={handleUpload}>
                  Upload
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TambahBanner;

// store/reviewService.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reviewService from "../services/reviewService";

export const fetchReviewData = createAsyncThunk(
  "review/fetchReviewData",
  async (page = 0) => {
    const response = await reviewService.fetchReviewData(page);
    return response;
  }
);

export const fetchReviewId = createAsyncThunk(
  "review/fetchReviewId",
  async (reviewId) => {
    const response = await reviewService.fetchReviewId(reviewId);
    return response.data;
  }
);

const reviewSlice = createSlice({
  name: "review",
  initialState: {
    data: [],
    editData: {},
    loading: false,
    editLoading: false,
    totalRows: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReviewData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalRows = action.payload.totalRow || action.payload.total;
        console.log("Total Rows:", state.totalRows);
        state.loading = false;
      })
      .addCase(fetchReviewId.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      });
  },
});

export default reviewSlice.reducer;

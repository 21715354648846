import React from "react";
import "@inovua/reactdatagrid-community/index.css";
import { Col, Container, Row } from "react-bootstrap";

import TableData from "../../part/Table";
import Input from "../../part/Input";
import CustomButtonGroup from "../../part/GroupButton";

const Produk = ({ detailData }) => {
  return (
    <div className="card mb-3 p-3">
      <div className="d-flex justify-content-between mb-3">
        <p className="text-primary fw-bold fs-5">Detail Data Produk :</p>
        <div>
          <CustomButtonGroup
            color={["success", "primary", "warning"]}
            text={["Save", "Shipping Label", "Factur"]}
          />
        </div>
      </div>
      <Container className="card mb-3 p-2">
        <h5>Jumlah :</h5>
        <Row>
          <Col>
            <Input placeholder="Stok Gabungan" disabled={true} />
          </Col>
          <Col>
            <Input placeholder="Online" disabled={true} />
          </Col>
          <Col>
            <Input placeholder="Toko Kalibata" disabled={true} />
          </Col>
        </Row>
      </Container>
      <Container className="card mb-3 p-2">
        <h5>List Order :</h5>
        <Row>
          <Col>
            <Input
              title="No Factur :"
              value={detailData.no_faktur}
              disabled={true}
            />
          </Col>
          <Col>
            <Input
              title="No Invoice :"
              value={detailData.invoice_no}
              disabled={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Produk;

import React, { useState, useEffect } from "react";

// lib
import { Container, Row, Button, Col } from "react-bootstrap";
import { Steps, Modal } from "antd";

// icons
import { LoadingOutlined } from "@ant-design/icons";
import { FaTruckFast } from "react-icons/fa6";
import { PiPackageFill } from "react-icons/pi";
import { FaHandHoldingHeart } from "react-icons/fa";
import { GiReturnArrow } from "react-icons/gi";
import { TbBasketCancel } from "react-icons/tb";
import Input from "../../part/Input";
import SingleButton from "../../part/Button";

// replacer
const { confirm } = Modal;

const AksiPesanan = ({ detailData, onRefresh }) => {
  console.log("order status id", detailData.order_status_id);
  console.log("order id", detailData.order_id);
  const [awb, setAwb] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [steps, setSteps] = useState([
    {
      title: "Packing Process",
      status: "wait",
      icon: <PiPackageFill />,
      disabled: true,
      action: () => handleAction(0),
    },
    {
      title: "Shipping",
      status: "wait",
      icon: <FaTruckFast />,
      disabled: true,
      action: () => handleAction(1),
    },
    {
      title: "Complete",
      status: "wait",
      icon: <FaHandHoldingHeart />,
      disabled: true,
      action: () => handleAction(2),
    },
    {
      title: "Return",
      status: "wait",
      icon: <GiReturnArrow />,
      disabled: true,
      action: () => handleAction(3),
    },
    {
      title: "Cancel",
      status: "wait",
      icon: <TbBasketCancel />,
      disabled: true,
      action: () => handleAction(4),
    },
  ]);

  useEffect(() => {
    const updatedSteps = [...steps];
    // if status new order
    updatedSteps[0].status =
      detailData.order_status_id === "0"
        ? "process"
        : "wait" && detailData.order_status_id === "15"
        ? "finish"
        : "wait" && detailData.order_status_id === "3"
        ? "finish"
        : "wait" && detailData.order_status_id === "6"
        ? "finish"
        : "wait" && detailData.order_status_id === "7"
        ? "finish"
        : "wait" && detailData.order_status_id === "5"
        ? "finish"
        : "wait";
    updatedSteps[0].disabled =
      detailData.order_status_id === "0" ? false : true;
    // if status process
    updatedSteps[1].status =
      detailData.order_status_id === "15"
        ? "process"
        : "wait" && detailData.order_status_id === "3"
        ? "finish"
        : "wait" && detailData.order_status_id === "6"
        ? "finish"
        : "wait" && detailData.order_status_id === "7"
        ? "finish"
        : "wait" && detailData.order_status_id === "5"
        ? "finish"
        : "wait";
    updatedSteps[1].disabled =
      detailData.order_status_id === "15" ? false : true;
    // if status shipping
    updatedSteps[2].status =
      detailData.order_status_id === "3"
        ? "process"
        : "wait" && detailData.order_status_id === "5"
        ? "finish"
        : "wait" && detailData.order_status_id === "6"
        ? "finish"
        : "wait" && detailData.order_status_id === "7"
        ? "error"
        : "wait";
    updatedSteps[2].disabled =
      detailData.order_status_id === "3" ? false : true;
    updatedSteps[3].status =
      detailData.order_status_id === "5"
        ? "process"
        : "wait" && detailData.order_status_id === "6"
        ? "finish"
        : "wait" && detailData.order_status_id === "7"
        ? "error"
        : "wait";
    updatedSteps[3].disabled =
      detailData.order_status_id === "5" ? false : true;
    updatedSteps[4].status =
      detailData.order_status_id === "6"
        ? "process"
        : "wait" && detailData.order_status_id === "7"
        ? "finish"
        : "wait" && detailData.order_status_id === "15"
        ? "process"
        : "wait";
    updatedSteps[4].disabled =
      detailData.order_status_id === "6"
        ? false
        : true && detailData.order_status_id === "15"
        ? false
        : true;
    setSteps(updatedSteps);
  }, [detailData]);

  const handleAction = async (stepIndex) => {
    if (stepIndex === 1) {
      setModalVisible(true);
    } else {
      // For other steps, show confirmation modal
      const stepTitle = steps[stepIndex].title;
      confirm({
        title: `Yakin untuk mengkonfirmasi langkah "${stepTitle}"?`,
        okText: "Yes",
        okType: "primary",
        cancelText: "No",
        async onOk() {
          try {
            const formDataObject = new FormData();
            if (detailData && detailData.order_id) {
              formDataObject.append("order_id", detailData.order_id);

              const response = await fetch(
                stepIndex === 2
                  ? "https://ujianonline.eurekabookhouse.co.id/order/order_complete"
                  : stepIndex === 3
                  ? "https://ujianonline.eurekabookhouse.co.id/order/order_return"
                  : stepIndex === 4
                  ? "https://ujianonline.eurekabookhouse.co.id/order/order_cancel"
                  : "https://ujianonline.eurekabookhouse.co.id/order/order_process",
                {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
                  },
                  body: formDataObject,
                }
              );
              if (response.ok) {
                const updatedSteps = [...steps];
                updatedSteps[stepIndex].status = "finish";
                updatedSteps[stepIndex].disabled = true;
                if (stepIndex < updatedSteps.length - 1) {
                  updatedSteps[stepIndex + 1].status = "process";
                  updatedSteps[stepIndex + 1].disabled = false;
                }
                onRefresh();
                setSteps(updatedSteps);
              } else {
                throw new Error(
                  stepIndex === 2
                    ? "Failed to complete order"
                    : stepIndex === 3
                    ? "Failed to return order"
                    : "Failed to cancel order"
                );
              }
            } else {
              console.log("Data pesanan belum tersedia.");
            }
          } catch (error) {
            console.error("Error processing order:", error);
          }
        },
        onCancel() {
          console.log("Action canceled");
        },
      });

      handleSaveAwb(stepIndex);
    }
  };

  const handleSaveAwb = async (stepIndex) => {
    // Proceed with saving AWB and update status
    try {
      const formDataObject = new FormData();
      if (detailData && detailData.order_id) {
        formDataObject.append("order_id", detailData.order_id);
        formDataObject.append("awb", awb);

        const response = await fetch(
          "https://ujianonline.eurekabookhouse.co.id/order/order_delivery",
          {
            method: "POST",
            headers: {
              Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
            },
            body: formDataObject,
          }
        );
        if (response.ok) {
          const updatedSteps = [...steps];
          updatedSteps[1].status = "finish";
          updatedSteps[1].disabled = true;
          if (stepIndex < updatedSteps.length - 1) {
            updatedSteps[stepIndex + 1].status = "process";
            updatedSteps[stepIndex + 1].disabled = false;
          }
          setSteps(updatedSteps);
          setModalVisible(false);
          onRefresh();
        } else {
          throw new Error("Failed to deliver order");
        }
      } else {
        console.log("Data pesanan belum tersedia.");
      }
    } catch (error) {
      console.error("Error processing order:", error);
    }
  };

  return (
    <Container className="card mb-3 p-3">
      <Container>
        <Row className="d-flex justify-content-between mb-3">
          <p className="text-primary fw-bold fs-5">Update Status Pesanan :</p>
        </Row>
        <Row>
          <Steps
            items={steps.map((step, index) => ({
              title: step.title,
              status: step.status,
              icon: step.status === "wait" ? <LoadingOutlined /> : step.icon,
              description: (
                <Button
                  variant="primary"
                  onClick={() => handleAction(index)}
                  disabled={step.disabled}
                >
                  Update Status {step.title}
                </Button>
              ),
            }))}
          />
        </Row>
      </Container>

      <Modal
        title="Masukkan AWB"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <div className="d-flex justify-content-end">
            <SingleButton
              key="cancel"
              color="danger"
              onClick={() => setModalVisible(false)}
            >
              Batal
            </SingleButton>
            <SingleButton key="save" color="primary" onClick={handleSaveAwb}>
              Simpan
            </SingleButton>
          </div>,
        ]}
      >
        <Input
          placeholder="Masukkan AWB"
          value={awb}
          onChange={(e) => setAwb(e.target.value)}
          name="awb"
        />
      </Modal>
    </Container>
  );
};

export default AksiPesanan;

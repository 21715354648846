// src/pages/Home.js
import React, { useState } from "react";
import TablePesanan from "../../components/pesanan/TablePesanan";
import ModalComponent from "../../components/part/Modal";
import { Button } from "react-bootstrap";
import CustomButtonGroup from "../../components/part/GroupButton";

const Home = () => {
  return (
    <div>
      <TablePesanan />
    </div>
  );
};

export default Home;

import React from "react";
import { TabsComponent, TabPane } from "../part/Tabs";
import Data from "./tab-detail-kupon/Data";
import Konten from "./tab-detail-kupon/Konten";
import History from "./tab-detail-kupon/History";

const DetailKupon = ({ couponData, onSave }) => {
  return (
    <div>
      <TabsComponent>
        <TabPane tab="Detail" key="1">
          <Data couponData={couponData} onSave={onSave} />
        </TabPane>
        <TabPane tab="Konten" key="2">
          <Konten />
        </TabPane>
        <TabPane tab="History" key="3">
          <History />
        </TabPane>
      </TabsComponent>
    </div>
  );
};

export default DetailKupon;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import BukuFlat from "./tab-detail-kategori/BukuFlat";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchCategoryId } from "../../redux/store/categorySlice";
import PromoTambahan from "./tab-detail-kategori/PromoTambahan";
import SubKategori from "./tab-detail-kategori/SubKategori";
import { Skeleton } from "antd";
import SingleButton from "../part/Button";

const EditKategori = ({ onSave }) => {
  const location = useLocation();
  const { categoryId } = useParams();
  const [categoryData, setCategoryData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/kategori");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCategoryId(categoryId));
        setCategoryData(response.payload);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };

    fetchData();
  }, [dispatch, categoryId]);

  return (
    <div>
      <Container>
        <div className="d-flex justify-content-end mb-3">
          <div>
            <SingleButton color="primary" onClick={handleBack}>
              Kembali
            </SingleButton>
          </div>
        </div>
        <Row>
          <Col>
            {categoryData ? (
              <BukuFlat categoryData={categoryData} onSave={onSave} />
            ) : (
              <Skeleton active />
            )}
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <PromoTambahan />
          </Col>
        </Row>
        <Row>
          <Col>
            <SubKategori />
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default EditKategori;

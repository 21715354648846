import api from "../utils/api";

const reviewService = {
  fetchReviewData: async (page) => {
    const start = page * 10;
    const length = 10;
    return await api.get(`/order/review_get?start=${start}&length=${length}`);
  },

  fetchReviewId: async (reviewId) => {
    return await api.get(`/order/review_id_get/${reviewId}`);
  },
};

export default reviewService;

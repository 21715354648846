import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import Input from "../part/Input";
import SelectComponent from "../part/Select";

const TambahKupon = ({ onSave }) => {
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    jenis: "",
    potongan: "",
    type: "",
    min_belanja: "",
    discount: "",
    logged: "",
    shipping: "",
    total: "",
    date_start: "",
    date_end: "",
    status: 0,
  });

  const handleSave = async () => {
    try {
      const response = await fetch(
        "https://ujianonline.eurekabookhouse.co.id/marketing/coupon_create",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
          },
          body: createFormData(),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Saved data:", result);
      onSave();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const createFormData = () => {
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("code", formData.code);
    formDataObj.append("jenis", formData.jenis);
    formDataObj.append("potongan", formData.potongan);
    formDataObj.append("type", formData.type);
    formDataObj.append("discount", formData.discount);
    formDataObj.append("min_belanja", formData.min_belanja);
    formDataObj.append("logged", formData.logged);
    formDataObj.append("shipping", formData.shipping);
    formDataObj.append("total", formData.total);
    formDataObj.append("date_start", formData.date_start);
    formDataObj.append("date_end", formData.date_end);
    formDataObj.append("status", formData.status);

    return formDataObj;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <div className="card p-3">
      <Container>
        <Row>
          <Col>
            <Input
              title="Judul Kupon"
              value={formData.name}
              onChange={handleChange}
              name="name"
            />
          </Col>
          <Col>
            <Input
              title="Kode Kupon"
              type="number"
              value={formData.code}
              onChange={handleChange}
              name="code"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Jenis"
              value={formData.jenis}
              onChange={handleChange}
              name="jenis"
            />
          </Col>
          <Col>
            <Input
              title="Potongan"
              value={formData.potongan}
              onChange={handleChange}
              name="potongan"
            />
          </Col>
          <Col>
            <Input
              title="type"
              value={formData.type}
              onChange={handleChange}
              name="type"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Minimum"
              type="number"
              value={formData.min_belanja}
              onChange={handleChange}
              name="min_belanja"
            />
          </Col>
          <Col>
            <Input
              title="Diskon"
              type="number"
              value={formData.discount}
              onChange={handleChange}
              name="discount"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Logged"
              type="number"
              value={formData.logged}
              onChange={handleChange}
              name="logged"
            />
          </Col>
          <Col>
            <Input
              title="Shipping"
              type="number"
              value={formData.shipping}
              onChange={handleChange}
              name="shipping"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Total"
              value={formData.total}
              onChange={handleChange}
              name="total"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Tanggal Mulai"
              type="date"
              value={formData.date_start}
              onChange={handleChange}
              name="date_start"
            />
          </Col>
          <Col>
            <Input
              title="Tanggal Akhir"
              type="date"
              value={formData.date_end}
              onChange={handleChange}
              name="date_end"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectComponent title="Status" option={["Aktif", "Tidak Aktif"]} />
          </Col>
        </Row>
      </Container>
      <div>
        <Button variant="success" onClick={handleSave}>
          Simpan
        </Button>
      </div>
    </div>
  );
};

export default TambahKupon;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "../../services/report/dashboardService";

export const fetchDashboard = createAsyncThunk(
  "dashboard/fetchDashboard",
  async () => {
    const response = await dashboardService.fetchDashboard();
    return response.data;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    data: [],
    loading: false,
    editLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      });
  },
});

export default dashboardSlice.reducer;

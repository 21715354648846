import React from "react";
import { Button } from "antd";

const SingleButton = (props) => {
  const { color, size = "default", onClick, children, icon, key } = props;

  let buttonColor = color;
  let textColor = "white";

  if (color === "success") {
    buttonColor = "green";
  } else if (color === "danger") {
    buttonColor = "red";
  } else if (color === "warning") {
    buttonColor = "yellow";
    textColor = "black";
  }

  const buttonStyle = {
    backgroundColor: buttonColor,
    color: textColor,
  };

  return (
    <Button
      icon={icon}
      type={color}
      size={size}
      onClick={onClick}
      block
      style={buttonStyle}
      key={key}
    >
      {children}
    </Button>
  );
};

export default SingleButton;

// store/productSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "../services/productService";

export const fetchProductData = createAsyncThunk(
  "product/fetchProductData",
  async ({ page = 0, search = "" }) => {
    const response = await productService.fetchProductData(page, search);
    return response;
  }
);

export const fetchProductId = createAsyncThunk(
  "product/fetchProductId",
  async (productId) => {
    const response = await productService.fetchProductId(productId);
    console.log("product data:", response.data);
    return response.data;
  }
);

export const updateProductData = createAsyncThunk(
  "product/updateProductData",
  async (productData) => {
    const response = await productService.updateProduct(productData);
    return response.data;
  }
);

export const createProductData = createAsyncThunk(
  "product/createProductData",
  async (productData) => {
    const response = await productService.createProduct(productData);
    return response.data;
  }
);

export const deleteProductData = createAsyncThunk(
  "product/deleteproductData",
  async (productId) => {
    const response = await productService.deleteProduct(productId);
    return response.data;
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    data: [],
    editData: {},
    loading: false,
    editLoading: false,
    totalRows: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalRows = action.payload.totalRow || action.payload.total;
        console.log("Total Rows:", state.totalRows);
        state.loading = false;
      })
      .addCase(fetchProductId.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchProductId.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      })
      .addCase(updateProductData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(createProductData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(deleteProductData.fulfilled, (state, action) => {
        console.log(action.payload);
      });
  },
});

export default productSlice.reducer;

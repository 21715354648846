import api from "../utils/api";

const authService = {
  login: async (username, password) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    const response = await api.postLogin("/auth/login", formData);

    // Save token to localStorage
    localStorage.setItem("token", response.data.token);

    return response.data;
  },
};

export default authService;

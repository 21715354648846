import React, { useState, useEffect } from "react";
import categoryService from "../../redux/services/categoryService";
import { fetchOptionCategory } from "../../redux/store/option/categoryOptionSlice";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../part/Upload";
import Input from "../part/Input";
import TextArea from "../part/TextArea";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactSelect from "../../components/part/ReactSelect";

const TambahKategori = ({ onSave }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    parent_id: 0,
    name: "",
    description: "",
    meta_description: "",
    meta_keyword: "",
    seo: "",
  });

  const handleSelectChange = (name, selectedOption) => {
    console.log(`Selected ${name}:`, selectedOption.value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : null,
    }));
  };

  const optionCategory = useSelector((state) => state.categoryOption.data);

  const handleSave = async () => {
    try {
      await categoryService.createCategory(formData);
      onSave();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  useEffect(() => {
    dispatch(fetchOptionCategory());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Upload title="Photo Menu" />
          </Col>
          <Col>
            <div className="card w-50 mb-3"></div>
            <p className="text-muted">
              Max. 500 kb Type file (gif,jpg,png,bmp)
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Title"
              value={formData.name}
              onChange={handleChange}
              name="name"
            />
          </Col>
          <Col>
            <ReactSelect
              label="Induk Kategori"
              options={optionCategory}
              onChange={(selectedOption) =>
                handleSelectChange("parent_id", selectedOption)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="SEO"
              value={formData.seo}
              onChange={handleChange}
              name="seo"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              title="Description"
              value={formData.meta_description}
              onChange={handleChange}
              name="meta_description"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Keyword"
              value={formData.meta_keyword}
              onChange={handleChange}
              name="meta_keyword"
            />
          </Col>
        </Row>
        <div className="d-flex gap-2">
          <Button variant="success" size="sm" onClick={handleSave}>
            Simpan
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default TambahKategori;

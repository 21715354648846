// store/merekSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import merekService from "../services/merekService";

export const fetchMerekData = createAsyncThunk(
  "merek/fetchMerekData",
  async (page = 0) => {
    const response = await merekService.fetchMerekData(page);
    return response;
  }
);

export const fetchMerekId = createAsyncThunk(
  "merek/fetchMerekId",
  async (merekId) => {
    const response = await merekService.fetchMerekId(merekId);
    return response.data;
  }
);

export const updateMerekId = createAsyncThunk(
  "merek/updateMerekId",
  async (merekData) => {
    const response = await merekService.updateMerek(merekData);
    return response.data;
  }
);

export const createMerekData = createAsyncThunk(
  "merek/createMerekData",
  async (merekData) => {
    const response = await merekService.createMerek(merekData);
    return response.data;
  }
);

export const deleteMerekData = createAsyncThunk(
  "merek/deleteMerekData",
  async (merekId) => {
    const response = await merekService.deleteMerek(merekId);
    return response.data;
  }
);

const merekSlice = createSlice({
  name: "merek",
  initialState: {
    data: [],
    editData: {},
    loading: false,
    editLoading: false,
    totalRows: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerekData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMerekData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalRows = action.payload.totalRow || action.payload.total;
        console.log("Total Rows:", state.totalRows);
        state.loading = false;
      })
      .addCase(fetchMerekId.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchMerekId.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.editLoading = false;
      })
      .addCase(updateMerekId.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(createMerekData.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(deleteMerekData.fulfilled, (state, action) => {
        console.log(action.payload);
      });
  },
});

export default merekSlice.reducer;

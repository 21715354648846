import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReviewData, fetchReviewId } from "../../redux/store/reviewSlice";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { Button } from "react-bootstrap";
import ModalComponent from "../part/Modal";
import DetailReview from "./DetailReview";
import CustomButtonGroup from "../part/GroupButton";
import { Pagination, Tag } from "antd";
import SingleButton from "../part/Button";
import TableData from "../part/Table";

const TableReview = () => {
  const dispatch = useDispatch();
  const { data, loading, editData, totalRows } = useSelector(
    (state) => state.review
  );

  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("tambah");
  const [selectReviewid, setSelectedReviewId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleShowDetail = async (reviewId) => {
    setSelectedReviewId(reviewId);
    await dispatch(fetchReviewId(reviewId));
    setShowModal(true);
    setActionType("edit");
    console.log("Selected manufacture ID:", reviewId);
  };

  const handleClose = () => setShowModal(false);
  const gridStyle = { minHeight: 500, marginTop: 10 };

  const columns = [
    {
      dataIndex: "id",
      title: "No",
      render: (text, record, index) => index + 1 + (currentPage - 1) * 10,
    },
    { dataIndex: "product_name", title: "Produk" },
    { dataIndex: "author", title: "Author" },
    { dataIndex: "rating", title: "Rating" },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) =>
        record.status === "0" ? (
          <Tag color="red">Disabled</Tag>
        ) : (
          <Tag color="green">Enabled</Tag>
        ),
    },
    { dataIndex: "date_added", title: "Tgl Ditambahkan" },
    {
      dataIndex: "actions",
      title: "Aksi",
      render: (text, record) => (
        <SingleButton
          color="primary"
          size="small"
          onClick={() => handleShowDetail(record.review_id)}
          block
        >
          Detail
        </SingleButton>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchReviewData(page - 1));
  };

  useEffect(() => {
    dispatch(fetchReviewData());
  }, [dispatch]);

  return (
    <div className="card w-full p-3 mb-3">
      <h5 className="mb-3">List Review</h5>
      <ModalComponent
        size="xl"
        showModal={showModal}
        handleClose={handleClose}
        title={"Detail Data Review"}
      >
        <DetailReview reviewData={editData} />
      </ModalComponent>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableData columns={columns} dataSource={data} />
      )}
      <div style={{ marginTop: 16, textAlign: "center" }}>
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          total={totalRows}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default TableReview;

import React, { useEffect, useState } from "react";

import { Container, Col, Row } from "react-bootstrap";

import TableData from "../part/Table";
import Input from "../part/Input";
import ReactSelect from "../part/ReactSelect";
import SingleButton from "../part/Button";

const AksiPesanan = ({ orderData, onSave }) => {
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    if (!orderData) {
      return "loading";
    }
  }, [orderData]);

  const status = [
    // {
    //   value: 0,
    //   label: "New Order",
    // },
    // {
    //   value: 1,
    //   label: "Waiting Payment",
    // },
    {
      value: 2,
      label: "Packing Proccess",
    },
    {
      value: 3,
      label: "Shipping",
    },
    {
      value: 4,
      label: "Complete",
    },
    {
      value: 5,
      label: "Return",
    },
    {
      value: 6,
      label: "Cancel",
    },
  ];

  console.log(selectedStatus);

  const handleUpdate = async () => {
    try {
      if (selectedStatus === 2) {
        try {
          const formDataObject = new FormData();
          formDataObject.append("order_id", orderData.order_id);

          const response = await fetch(
            "https://ujianonline.eurekabookhouse.co.id/order/order_process",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
              },
              body: formDataObject,
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          console.log("Order updated successfully:", result);
        } catch (error) {
          console.error("Error updating order:", error);
        }
      } else if (selectedStatus === 6) {
        // Check if selectedStatus is "Cancel"
        try {
          const formDataObject = new FormData();
          formDataObject.append("order_id", orderData.order_id);

          const response = await fetch(
            "https://ujianonline.eurekabookhouse.co.id/order/order_cancel",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
              },
              body: formDataObject,
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          console.log("Order canceled successfully:", result);
        } catch (error) {
          console.error("Error canceling order:", error);
        }
      } else if (selectedStatus === 5) {
        try {
          const formDataObject = new FormData();
          formDataObject.append("order_id", orderData.order_id);

          const response = await fetch(
            "https://ujianonline.eurekabookhouse.co.id/order/order_return",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
              },
              body: formDataObject,
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          console.log("Order canceled successfully:", result);
        } catch (error) {
          console.error("Error canceling order:", error);
        }
      } else if (selectedStatus === 4) {
        try {
          const formDataObject = new FormData();
          formDataObject.append("order_id", orderData.order_id);

          const response = await fetch(
            "https://ujianonline.eurekabookhouse.co.id/order/order_complete",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
              },
              body: formDataObject,
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          console.log("Order canceled successfully:", result);
        } catch (error) {
          console.error("Error canceling order:", error);
        }
      } else if (selectedStatus === 3) {
        try {
          const formDataObject = new FormData();
          formDataObject.append("order_id", orderData.order_id);

          const response = await fetch(
            "https://ujianonline.eurekabookhouse.co.id/order/order_delivery",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
              },
              body: formDataObject,
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          console.log("Order canceled successfully:", result);
        } catch (error) {
          console.error("Error canceling order:", error);
        }
      }

      onSave();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const columns = [
    {
      dataIndex: "order_product_id",
      title: "No",
    },
    { dataIndex: "name", title: "Produk", defaultFlex: 2 },
    { dataIndex: "model", title: "Model", defaultFlex: 2 },
    { dataIndex: "quantity", title: "Qty", defaultFlex: 2 },
    { dataIndex: "price", title: "Unit Price", defaultFlex: 2 },
    { dataIndex: "total", title: "Total", defaultFlex: 2 },
  ];

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Input title="Shipping Code" value={orderData.shipping_id} />
          </Col>
          <Col>
            <Input title="Customer" value={orderData.firstname} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input title="Received Name" value={orderData.shipping_id} />
          </Col>
          <Col>
            <Input title="Phone Number" value={orderData.firstname} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input title="Received Address 1" value={orderData.email} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input title="Received Address 2" value={orderData.email} />
          </Col>
          <Col>
            <Input title="City" value={orderData.email} />
          </Col>
          <Col>
            <Input title="Pos Code" value={orderData.email} />
          </Col>
        </Row>
        <Row>
          <Col>
            <TableData columns={columns} dataSource={orderData.product_order} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <ReactSelect
              placeholder="Pilih Status..."
              options={status}
              value={status.find((s) => s.value === selectedStatus)}
              onChange={(option) => setSelectedStatus(option.value)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <SingleButton color="success" size="small" onClick={handleUpdate}>
              Update
            </SingleButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AksiPesanan;

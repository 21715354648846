import React from "react";

const Content = ({ isSidebarOpen, children }) => {
  return (
    <>
      <main
        className="content"
        style={{
          marginLeft: isSidebarOpen ? "20px" : "265px",
          marginRight: isSidebarOpen ? "20px" : "3px",
        }}
      >
        <div className="container">{children}</div>
      </main>
    </>
  );
};

export default Content;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderId } from "../../redux/store/orderSlice";
import DataPengirim from "./tab-detail-pesanan/DataPengirim";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import AlamatKirim from "./tab-detail-pesanan/AlamatKirim";
import Produk from "./tab-detail-pesanan/Produk";
import Total from "./tab-detail-pesanan/Total";
import SingleButton from "../part/Button";
import AksiPesanan from "./tab-detail-pesanan/AksiPesanan";
import Skeleton from "antd/lib/skeleton";
import TrackPesanan from "./tab-detail-pesanan/TrackPesanan";

const DetailPesanan = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const [detailData, setDetailData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/pesanan");
  };

  const onRefresh = async () => {
    try {
      setLoading(true);
      const response = await dispatch(fetchOrderId(orderId));
      setDetailData(response.payload);
    } catch (error) {
      console.error("Error fetching order data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchOrderId(orderId));
        setDetailData(response.payload);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };

    fetchData();
  }, [dispatch, orderId]);

  return (
    <div>
      <Container>
        <div className="d-flex justify-content-end mb-3">
          <div>
            <SingleButton color="primary" onClick={handleBack}>
              Kembali
            </SingleButton>
          </div>
        </div>
        <Row>
          <Col>
            {loading ? (
              <Skeleton active />
            ) : (
              <AksiPesanan detailData={detailData} onRefresh={onRefresh} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {loading ? (
              <Skeleton active />
            ) : (
              <TrackPesanan detailData={detailData} onRefresh={onRefresh} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {loading ? (
              <Skeleton active />
            ) : (
              <DataPengirim detailData={detailData} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {loading ? (
              <Skeleton active />
            ) : (
              <AlamatKirim detailData={detailData} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {loading ? <Skeleton active /> : <Produk detailData={detailData} />}
          </Col>
        </Row>
        <Row>
          <Col>
            {loading ? <Skeleton active /> : <Total detailData={detailData} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DetailPesanan;

import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

function CustomButtonGroup({ color, text, onClickHandler }) {
  const buttons = text.map((text, index) => (
    <Button
      className="text-white"
      key={index}
      variant={color && color[index] ? color[index] : "primary"}
      onClick={
        onClickHandler && onClickHandler[index]
          ? onClickHandler[index]
          : () => {}
      }
    >
      {text}
    </Button>
  ));

  return (
    <ButtonGroup size="sm" aria-label="Custom example">
      {buttons}
    </ButtonGroup>
  );
}

export default CustomButtonGroup;

// General.jsx
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchProductData } from "../../../redux/store/productSlice";
import { Col, Container, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";

import productService from "../../../redux/services/productService";
import Input from "../../part/Input";
import TextArea from "../../part/TextArea";
import SingleButton from "../../part/Button";
import ReactSelect from "../../part/ReactSelect";

const General = ({ productData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [updatedData, setUpdatedData] = useState(productData);
  const [quillValue, setQuillValue] = useState(productData.description || "");

  useEffect(() => {
    setUpdatedData(productData);
    setQuillValue(productData.description || "");
  }, [productData]);

  const handleUpdateProduct = async () => {
    try {
      await productService.updateProduct(updatedData);
      navigate("/produk");
      dispatch(fetchProductData());
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleSelectChange = (name, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleQuillChange = (content, delta, source, editor) => {
    setQuillValue(content);
    setUpdatedData((prevData) => ({
      ...prevData,
      description: content,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchSupplierOptions = async () => {
    try {
      const response = await fetch(
        "https://ujianonline.eurekabookhouse.co.id/master/option_supply_get",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setSupplierOptions(result.data);
    } catch (error) {
      console.error("Error fetching supplier options:", error);
    }
  };

  const fetchManufacturerOptions = async () => {
    try {
      const response = await fetch(
        "https://ujianonline.eurekabookhouse.co.id/master/option_manufacturer_get",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer qtUPRXyF8NsA3U37wcPNMw==",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setManufacturerOptions(result.data);
    } catch (error) {
      console.error("Error fetching manufacturer options:", error);
    }
  };

  const selectLayout = [
    {
      value: "0",
      label: "Buku",
    },
    {
      value: "1",
      label: "Sepatu",
    },
    {
      value: "2",
      label: "Umum",
    },
  ];

  const selectStatus = [
    {
      value: "1",
      label: "Enable",
    },
    {
      value: "0",
      label: "Disable",
    },
  ];

  const defaultStatusOption = selectStatus.find(
    (option) => option.value === parseInt(productData.status, 10)
  );

  const defaultLayoutOption = selectLayout.find(
    (option) => option.value === parseInt(productData.layout, 10)
  );

  return (
    <>
      <div className="card mb-3 p-3">
        <Container>
          <p className="text-primary fw-bold fs-5">Detail Data Pengirim:</p>
          <Row>
            <Col>
              <Input
                title="Nama Produk"
                value={updatedData.name}
                onChange={handleChange}
                name="name"
              />
            </Col>
            <Col>
              <Input
                title="Tag Produk"
                value={updatedData.tag}
                onChange={handleChange}
                name="tag"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="SEO"
                value={updatedData.seo}
                onChange={handleChange}
                name="seo"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                title="Description Tag Meta"
                content={updatedData.meta_description}
                onChange={handleChange}
                name="meta_description"
              />
            </Col>
            <Col>
              <TextArea
                title="Keyword Tag Meta"
                content={updatedData.meta_keyword}
                onChange={handleChange}
                name="meta_keyword"
              />
            </Col>
          </Row>
          {/* <Row className="mb-5">
            <Col>
              <ReactQuill
                theme="snow"
                value={quillValue}
                onChange={handleQuillChange}
              />
            </Col>
          </Row> */}
        </Container>
      </div>
      <div className="card p-3 mb-3">
        <Container>
          <p className="text-primary fw-bold fs-5">Data :</p>
          <Row>
            {/* <Col>
              <SelectComponent label="Supplier" option={supplierOptions} />
            </Col>
            <Col>
              <SelectComponent label="Merk" option={manufacturerOptions} />
            </Col> */}
            <Col>
              <Input
                title="Model"
                value={updatedData.model}
                onChange={handleChange}
                name="model"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Author"
                value={updatedData.author}
                onChange={handleChange}
                name="author"
              />
            </Col>
            <Col>
              <Input
                title="SKU"
                value={updatedData.sku}
                onChange={handleChange}
                name="sku"
              />
            </Col>
            <Col>
              <Input
                title="ISBN"
                value={updatedData.isbn}
                onChange={handleChange}
                name="isbn"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Lokasi"
                value={updatedData.location}
                onchange={handleChange}
                name="location"
              />
            </Col>
            <Col>
              <Input
                title="Minimum"
                value={updatedData.minimum}
                onchange={handleChange}
                name="minimum"
              />
            </Col>
            <Col>
              <ReactSelect
                defaultValue={defaultLayoutOption}
                label="Layout"
                options={selectLayout}
                onChange={(selectedOption) =>
                  handleSelectChange("layout", selectedOption.value)
                }
                value={selectLayout.find(
                  (option) => option.value === updatedData.layout
                )}
              />
            </Col>
            <Col>
              <Input
                title="Harga"
                value={updatedData.price}
                onchange={handleChange}
                name="price"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Point"
                value={updatedData.points}
                onchange={handleChange}
                name="points"
              />
            </Col>
            <Col>
              <Input
                title="Diskon"
                value={updatedData.diskon}
                onchange={handleChange}
                name="diskon"
              />
            </Col>
            <Col>
              <Input
                title="Berat"
                value={updatedData.weight}
                onchange={handleChange}
                name="weight"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                title="Stok Gabungan"
                value={updatedData.storage_quantity}
                onchange={handleChange}
                name="storage_quantity"
              />
            </Col>
            <Col>
              <Input
                title="Stok Online"
                value={updatedData.stock}
                onChange={handleChange}
                name="store_quantity"
              />
            </Col>
            {/* <Col>
              <Input
                title="Toko Kalibata"
                value={updatedData.store_quantity}
                onChange={handleChange}
                name="store_quantity"
              />
            </Col> */}
          </Row>
          <Row>
            <Col>
              <Input
                title="Tanggal Tersedia"
                type="date"
                value={updatedData.date_available}
                onChange={handleChange}
                name="date_available"
              />
            </Col>
            <ReactSelect
              defaultValue={defaultStatusOption}
              label="Status"
              options={selectStatus}
              onChange={(selectedOption) =>
                handleSelectChange("status", selectedOption.value)
              }
              value={selectStatus.find(
                (option) => option.value === updatedData.status
              )}
            />
            {/* <Col>
              <Input
                title="Dimensi"
                value={updatedData.dimensions}
                onChange={handleChange}
              />
            </Col> */}
          </Row>
          <Row className="mt-3">
            <Col>
              <SingleButton color="success" onClick={handleUpdateProduct}>
                Simpan
              </SingleButton>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default General;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Input from "../../part/Input";
import SelectComponent from "../../part/Select";
import couponService from "../../../redux/services/couponService";
import ReactSelect from "../../part/ReactSelect";

const Data = ({ couponData, onSave }) => {
  useEffect(() => {
    if (!couponData) {
      return "loading";
    }
  }, [couponData]);

  const [editedData, setEditedData] = useState(couponData);

  const handleSave = async () => {
    try {
      await couponService.updateCoupon(editedData);
      onSave();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSelectChange = (name, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const opsi = [
    {
      value: "0",
      label: "Tidak",
    },
    {
      value: "1",
      label: "Ya",
    },
  ];

  const tipePotongan = [
    {
      value: "0",
      label: "Persen",
    },
    {
      value: "jumlah_tetap",
      label: "Jumlah Tetap",
    },
  ];
  const web = [
    {
      value: "0",
      label: "EBH",
    },
    {
      value: "1",
      label: "BE",
    },
  ];
  const jenis = [
    {
      value: "0",
      label: "Ongkir",
    },
    {
      value: "1",
      label: "Potongan",
    },
  ];

  const defaultStatusOption = opsi.find(
    (option) => option.value === parseInt(couponData.status, 10)
  );

  const defaultStatusLogged = opsi.find(
    (option) => option.value === parseInt(couponData.logged, 10)
  );

  const defaultOptionPotongan = tipePotongan.find(
    (option) => option.value === parseInt(couponData.potongan, 10)
  );

  const defaultOptionjenis = jenis.find(
    (option) => option.value === parseInt(couponData.jenis, 10)
  );

  return (
    <div>
      <Container>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-2">
            <h5>General</h5>
          </div>
          <div>
            <Button
              variant="success"
              size="sm"
              className="text-white"
              onClick={handleSave}
            >
              Simpan Detail
            </Button>
          </div>
        </div>
        <Row>
          <Col>
            <Input
              title="Name Kupon"
              value={editedData.name}
              onChange={handleChange}
              name="name"
            />
          </Col>
          <Col>
            <ReactSelect
              defaultValue={defaultStatusOption}
              label="Status"
              options={opsi}
              onChange={(selectedOption) =>
                handleSelectChange("status", selectedOption.value)
              }
              value={opsi.find((option) => option.value === editedData.status)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactSelect
              defaultValue={defaultOptionPotongan}
              label="Tipe Potongan"
              options={tipePotongan}
              onChange={(selectedOption) =>
                handleSelectChange("potongan", selectedOption.value)
              }
              value={tipePotongan.find(
                (option) => option.value === editedData.potongan
              )}
            />
          </Col>
          <Col>
            <ReactSelect
              defaultValue={defaultStatusLogged}
              label="Status Login"
              options={opsi}
              onChange={(selectedOption) =>
                handleSelectChange("logged", selectedOption.value)
              }
              value={opsi.find((option) => option.value === editedData.logged)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactSelect
              defaultValue={defaultOptionjenis}
              label="Jenis"
              options={jenis}
              onChange={(selectedOption) =>
                handleSelectChange("jenis", selectedOption.value)
              }
              value={opsi.find((option) => option.value === editedData.jenis)}
            />
          </Col>
          <Col>
            <SelectComponent label="Gratis Pengiriman" option={opsi} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Kode Kupon"
              value={editedData.code}
              onChange={handleChange}
              name="code"
            />
          </Col>
          <Col>
            <Input
              type="date"
              title="Tanggal Mulai"
              value={editedData.date_start}
              onChange={handleChange}
              name="date_start"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              title="Diskon Kupon"
              value={editedData.discount}
              onChange={handleChange}
              name="discount"
            />
          </Col>
          <Col>
            <Input
              title="Tanggal Akhir"
              type="date"
              value={editedData.date_end}
              onChange={handleChange}
              name="date_end"
            />
          </Col>
        </Row>
        <Row>
          {/* <Col>
            <SelectComponent label="Web" option={web} />
          </Col> */}
          <Col>
            <Input
              title="Jumlah Total"
              value={editedData.total}
              onChange={handleChange}
              name="total"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Data;

import api from "../utils/api";

const productService = {
  fetchProductData: async (page, search) => {
    const start = page * 10;
    const length = 10;
    return await api.get(
      `/master/product_get?start=${start}&length=${length}&search=${search}`
    );
  },

  fetchProductId: async (productId) => {
    return await api.get(`/master/product_id_get/${productId}`);
  },

  updateProduct: async (productData) => {
    const formData = new FormData();
    formData.append("product_id", productData?.product_id);
    formData.append("name", productData?.name);
    formData.append("tag", productData?.tag);
    formData.append("seo", productData?.seo);
    formData.append("meta_description", productData?.meta_description);
    formData.append("meta_keyword", productData?.meta_keyword);
    formData.append("description", productData?.description);
    formData.append("model", productData?.model);
    formData.append("author", productData?.author);
    formData.append("price", productData?.price);
    formData.append("diskon", productData?.diskon);
    formData.append("weight", productData?.weight);
    formData.append("sku", productData?.sku);
    formData.append("isbn", productData?.isbn);
    formData.append("location", productData?.location);
    formData.append("points", productData?.points);
    formData.append("date_available", productData?.date_available);
    formData.append("status", productData?.status);
    formData.append("minimum", productData?.minimum);

    return await api.post("/master/product_update", formData);
  },

  createProduct: async (productData) => {
    const formData = new FormData();
    formData.append("name", productData?.name);
    formData.append("tag", productData?.tag);
    formData.append("seo", productData?.seo);
    formData.append("meta_description", productData?.meta_description);
    formData.append("meta_keyword", productData?.meta_keyword);
    formData.append("description", productData?.description);
    formData.append("model", productData?.model);
    formData.append("author", productData?.author);
    formData.append("price", productData?.price);
    formData.append("diskon", productData?.diskon);
    formData.append("weight", productData?.weight);
    formData.append("sku", productData?.sku);
    formData.append("isbn", productData?.isbn);
    formData.append("location", productData?.location);
    formData.append("points", productData?.points);
    formData.append("minimum", productData?.minimum);

    return await api.post("/master/product_create", formData);
  },

  deleteProduct: async (productId) => {
    const formData = new FormData();
    formData.append("product_id", productId);

    return await api.post("/master/product_delete", formData);
  },
};

export default productService;

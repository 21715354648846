import React, { useEffect, useState } from "react";
import SelectComponent from "../../part/Select";
import { Container, Row, Col } from "react-bootstrap";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

const History = () => {
  const gridStyle = { minHeight: 300, marginTop: 10 };

  const columns = [
    { name: "ID", defaultFlex: 2, header: "ID Pesanan" },
    { name: "aksi", defaultFlex: 2, header: "Kode Kupon" },
    { name: "nama", defaultFlex: 2, header: "Pelanggan" },
    { name: "aksi", defaultFlex: 2, header: "Jumlah" },
    { name: "aksi", defaultFlex: 2, header: "Tgl Ditambahkan" },
  ];

  const dataSource = [
    {
      id: 1,
      cover: "https://picsum.phosadtos/200/300",
      kategori: "Kategori",
      produk: "Produk",
      seo: "SEO",
      status: "Status",
    },
  ];

  return (
    <div>
      <Container>
        <h5>History</h5>
        <Row>
          <Col>
            <ReactDataGrid
              idProperty="id"
              style={gridStyle}
              columns={columns}
              pagination
              dataSource={dataSource}
              defaultLimit={10}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default History;
